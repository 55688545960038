<template>
    <img v-if="spot_banner != null" :src="spot_banner" @click.prevent="click" :class="{no_link: (props.link === false), spot_banner_disabled: $route.meta.signin_token == false}" v-cloak>
</template>


<script setup>
import { ref, defineProps } from 'vue'
import { useRoute, useRouter } from 'vue-router'

const router = useRouter()
const route = useRoute()

const props = defineProps({stamp_id: Number, link: String})

const spot_banner = ref(null)
if (props.stamp_id) {
    spot_banner.value = require('@/assets/images/about_view/spot' + props.stamp_id + '.png')
}

//クエリパラメータ引き回し
const search_params = new URLSearchParams(window.location.search)
const query = {}
for(let [k, v] of search_params) query[k] = v


//クリックイベント
const click = () => {
    if (route.meta.signin_token == false) return false
    if (props.link === false) return false

    if (props.link) router.push({path: props.link, query: query})
    else router.push({name: 'stamp', params: {stamp_id: props.stamp_id}, query: query})
}

</script>


<style scoped src="./SpotBanner.css"></style>
