<template>
  <div v-cloak id="app_wrapper" :style="{overflow: ((nav_open) ? 'hidden' : 'visible'), 'pointer-events': ((is_all_disabled) ? 'none' : 'auto')}">
    <div class="navigation_area" >

    <h1>{{ view_title }}</h1>
    <!-- ナビゲーション -->
    <nav>
    <p class="naviuse" :class="{'click_disabled': (! is_online)}"><a href="#" class="opacity" @click.prevent="show_modal('ModalHowto')" :disabled="! is_online"><span><img src="@/assets/images/icons/hatena.svg" alt="利用方法"></span></a></p>
    <!-- <p class="naviuse"><a href="#" @click.prevent="show_modal('ModalHowto')"><span><img src="@/assets/images/icons/hatena.svg" alt="利用方法"></span></a></p> -->
    <!-- <a href="#" @click.prevent="show_modal('ModalAgreement')">利用規約</a> -->
    </nav>

    <div class="navigation_all">
      <div class="navi">
        <input type="checkbox" id="menu-btn" v-model="nav_open"/>
        <label for="menu-btn" class="checkbtn"><span></span></label>
        <div id="navinner" @touchstart.stop="stop_event" @touchmove.stop="stop_event" @touchend.stop="stop_event">
          <nav class="navinner_nav">
            <h4 style="visibility: hidden;">MENU</h4>
            <div class="navigation_list">
<div @click.stop="nav_click">
            <router-link class="navbtn" :to="{name: 'about', query: query}" :class="{'is_hidden': (! is_online)}">HOME</router-link>
            <template v-if="signin_token != false && route.meta.in_term == 0"><!-- サインインしている -->
              <router-link class="navbtn" :to="{name: 'home', query: query}">スタンプ帳</router-link>
              <!-- <router-link class="navbtn" :to="{name: 'qr', query: query}" :class="{'is_hidden': (is_iphone && is_standalone && (! is_online))}">QRコード読取</router-link> -->
              <router-link v-if="constant.GPS_VERSION" class="navbtn" :to="{name: 'gps', query: query}">スタンプ獲得（位置情報取得）</router-link>
              <router-link v-else class="navbtn" :to="{name: 'qr', query: query}">QRコード読取</router-link>
            </template>
            <router-link class="navbtn" :to="{name: 'map', query: query}" :class="{'is_hidden': (! is_online)}">地図</router-link>
            <router-link class="navbtn" :to="{name: 'prizeexchange', query: query}" :class="{'is_hidden': (! is_online)}">プレゼント引換場所</router-link>
  
            <a href="#" class="navbtn" @click.prevent.stop="share">シェア</a>
  
            <p class="nav_version" @click.stop>バージョン<span>ver. {{ constant.VERSION }}</span></p>
  
            <a href="#" @click.prevent.stop="show_modal('ModalAgreement')">利用規約</a>
  
            <template v-if="route.meta.in_term >= 0">
              <template v-if="signin_token != false"><!-- サインインしている -->
                <a href="#" @click.prevent="show_signout_confirm">サインアウト</a>
              </template>
              <template v-else><!-- サインイン前 -->
                <router-link class="navbtn" :to="{name: 'signup', query: query}" :class="{'is_hidden': (! is_online)}">新規登録</router-link>
                <router-link class="navbtn" :to="{name: 'signin', query: query}" :class="{'is_hidden': (! is_online)}">サインイン</router-link>
              </template>
            </template> 

            </div>
</div>
          </nav>
        </div>
    </div></div><!--navigation_area-->
  </div>
  
  <div class="loading loader-wrap" v-if="is_loading">
    <div class="loader"></div>
  </div>

  <div class="details_area">  
      <!-- VIEW -->
      <div>
        <router-view :key="route.params.stamp_id" v-show="! is_loading" @unmounted="unmounted" @mounted="mounted" @show_modal="show_modal" @loading="loading" @signout="show_signout_confirm" @signout_force="signout" @all_disabled="all_disabled"/>
        <!-- <div class="loading" v-if="is_loading"></div> -->
      </div>
    </div><!--details_area-->
  
        <!-- モーダル -->
        <transition>
        <ModalWin @modal_close="modal_close" v-if="is_visible_modal">
          <Component v-if="is_visible_modal" :is="modal_list[modal_name]" @show_modal="show_modal" @modal_close="modal_close"/>
        </ModalWin>
        </transition>
  
        <!-- サインアウト確認 -->
        <ConfirmWin v-if="is_visible_confirm" @confirm_close="confirm_close">
          <div class="confirm_textbox">
          <h3>サインアウトしますか？</h3>
          <p>本アプリ利用期間中は、サインアウトする必要はありません。サインインするには、再度認証が必要になります。<br>また、インターネット接続がないとサインインできません。ご注意ください。</p>
          </div>
          <div class="confirm_signout"><p>サインアウトする</p></div>
        </ConfirmWin>
  
      <div class="footernavi_area">
        <!-- ナビゲーション（サインイン後に表示） -->
        <template v-if="signin_token != false">
        <nav class="footernavi_area_wrap">
            <ul class="footernavi">
              <li :class="{'click_disabled': (! is_online)}">
                <div v-if="$route.name == 'about'" class="active"><span class="home-icon foot_icon"></span><span>HOME</span></div>
                <router-link v-else :to="{name: 'about', query: query}" :disabled="! is_online"><span class="home-icon foot_icon"></span><span>HOME</span></router-link></li>
              <li>
                <div v-if="$route.name == 'home'" class="active"><span class="book-icon foot_icon"></span><span>スタンプ帳</span></div>
                <router-link v-else :to="{name: 'home', query: query}"><span class="book-icon foot_icon"></span><span>スタンプ帳</span></router-link>
              </li>
              
              <li v-if="constant.GPS_VERSION">
                <div v-if="$route.name == 'gps' || $route.name == 'gps_notice'" class="active"><span class="stamp-icon foot_icon"></span><span>スタンプ獲得</span></div>
                <router-link v-else :to="{name: 'gps', query: query}"><span class="stamp-icon foot_icon"></span><span>スタンプ獲得</span></router-link>
              </li>
              <li v-else>
                <div v-if="$route.name == 'qr' || $route.name == 'qr_notice'" class="active"><span class="stamp-icon foot_icon"></span><span>QRコード読取</span></div>
                <router-link v-else :to="{name: 'qr', query: query}"><span class="stamp-icon foot_icon"></span><span>QRコード読取</span></router-link>
              </li>
            </ul>
        </nav>
        </template>
  
  
        <!-- ホームへ追加 -->
        <template v-if="route.meta.in_term == 0 && is_online"> <!-- 期間内 -->
          <div class="homeadd" v-if="is_online && signin_token == false && $route.name != 'signin' && $route.name != 'signup'">
            <!-- <router-link :to="{name: 'signup', query: query}">新規登録・サインイン</router-link>       -->
            <!-- <router-link :to="{name: 'about', query: query, hash: '#register'}">新規登録・サインイン</router-link> -->

            <!-- <a v-if="$route.name != 'about' || w_scroll < regi_pos" href="#" @click.prevent="siginup_signin">新規登録・サインイン</a>
            <a v-else style="visibility: hidden;" >新規登録・サインイン</a> -->

            <a :style="{visibility: ($route.name != 'about' || w_scroll < regi_pos) ? 'visible' : 'hidden'}" @click.prevent="siginup_signin">新規登録・サインイン</a>

          </div>
          <div class="homeadd" @click.prevent="click_a2hs" v-else-if="is_online && (! is_standalone) && route.meta.a2hs && (is_iphone || deferred_prompt != null) && is_visible_a2hs">
            <p>ホーム画面に追加すると便利です</p>
            <span class="homeadd_close" @click.prevent.stop="is_visible_a2hs = false"></span>
          </div>
        </template>

          
      </div><!--footernavi_area-->

      </div><!-- app_wrapper -->
    
  </template>
  
  
  
  <script setup>
  import { ref, reactive, provide, inject, watch, onMounted, onErrorCaptured } from 'vue'
  import { useRouter, useRoute } from 'vue-router'
  import { useStore } from '@/stores/store'
  import ModalHowto from '@/components/ModalHowto/ModalHowto.vue'
  import ModalAgreement from '@/components/ModalAgreement/ModalAgreement.vue'
  import ModalWin from '@/components/ModalWin/ModalWin.vue'
  import ConfirmWin from '@/components/ConfirmWin/ConfirmWin.vue'
  import constant from '@/constant'
  
  
  const router = useRouter()
  const route = useRoute()
  const store = useStore()
    
  const view_title = ref('')
  
  
  //サインイン状態
  const signin_token = ref(localStorage.getItem(constant.SIGNIN_TOKEN) || false)
    //初期値はローカルストレージを直接参照。（初回は route の値は参照できないので）
    //ルーティングでVIEWがマウントされるたびに値を更新（mount()で route の meta 参照）
  
  //Alert表示用
  const toast = inject('toast');
    
  //iPhoneで利用
  const is_iphone = (/iPad|iPhone|iPod/.test(navigator.userAgent)) ? true : false
  
  //ホーム画面からの起動か、ブラウザか（true：ホーム画面）
  const is_standalone =  ((-1 != window.location.href.indexOf('?utm_source=homescreen')) || navigator.standalone) ? true : false
  provide(constant.IS_STANDALONE, is_standalone)

  //Qrnotice, Gpsnotice 表示用
  provide(constant.SHOW_NOTICE, ref(true))
  
  //クエリパラメータ引き回し
  const search_params = new URLSearchParams(window.location.search)
  const query = {}
  for(let [k, v] of search_params) query[k] = v
  
  //新規スタンプ獲得フラグ
  //'QrView.vue', 'StampView.vue' で利用
  provide(constant.NEW_STAMP, reactive({id: "", is_new: false}))
  
  
  
  /*************************************/
  //期間内
  /*************************************/
  const check_period = () => {
    const date_from = new Date(Date.parse(constant.FROM))
    const date_to = new Date(Date.parse(constant.TO + ' 23:59:59'))
    const date_now = new Date()
    let bool = (date_now >= date_from && date_now <= date_to)
    if (route.name != 'about' && (! bool)) router.replace({name: 'about', query: query})
    // return (date_now >= date_from && date_now <= date_to)
    return bool
  }
  const in_period = ref(check_period())
  
  
  
  /*************************************/
  //オフライン時に押したスタンプがあれば同期
  /*************************************/
  const _synchro_pending_stamps = () => {
    const promise = store.synchro_pending_stamps(signin_token.value) //store 同期
    if (promise == false) return  //同期対象なし
    promise.then(function(res) {
      if (res == false) {
        toast.show('同期エラー', {type: "error"}) 
      }
      else {
        if (('invalid_token' in res) && res.invalid_token == true) {
          toast.show('他の端末でサインインされました', {type: "error"}) 
          setTimeout(() => { signout() }, 5000)
        }
        // else {  //OK
        // }
      }
    })  //promise
  }
  
  
  
  /*************************************/
  // オンライン・オフラインの監視
  /*************************************/
  
  //インターネット接続がある（オンライン）か
  const is_online = ref(window.navigator.onLine)
  provide(constant.IS_ONLINE, is_online)
  
  window.addEventListener('online', () => { is_online.value = true })
  window.addEventListener('offline', () => { is_online.value = false })
  watch(is_online, (to, from) => {
    if (to) { //オンライン
      if (signin_token.value != false) {  //サインインしている
        //オフライン時に押したスタンプがあれば同期
        // store.synchro_pending_stamps()
        _synchro_pending_stamps()
      }
      if (from == undefined) return false   //初回（immediate）検査時はスキップ
      toast.show('インターネット接続が回復しました', { type: 'info' })
      in_period.value = check_period()
      if (route.name == 'offline') router.replace({name: 'home', query: query})
    }
    else { //オフライン
      // click_disabled.value = true
      toast.show('インターネット接続がありません', { type: 'warning' })
      //サインインしていない
      if (signin_token.value == false) router.replace({name: 'offline', query: query})
      else if (! route.meta.canShowOffline) router.push({name: 'home', query: query})
    }
  }, { immediate: true })
  
  
  
  /*************************************/
  // バックグラウンドからの復帰
  /*************************************/
  
  // document.addEventListener('resume', () => {
  //   //同期していないスタンプがあれば同期
  ////   if (signin_token.value != false) store.synchro_pending_stamps()
  //   if (signin_token.value != false) _synchro_pending_stamps()
  //   router.replace({name: 'home', query: query})
  // })
  
  //バックグラウンドから復帰したとき２（スマホではこちら？？）
  document.addEventListener("visibilitychange", () => {
    if (document.visibilityState == 'hidden') { //バックグラウンドに移動
      // if (route.name == 'qr') return false
      modal_close()
      confirm_close()
      router.replace({name: 'home', query: query})
    }
    else if (document.visibilityState != 'visible') return false
    //バックグラウンドから復帰
    //同期していないスタンプがあれば同期
    // if (signin_token.value != false) store.synchro_pending_stamps(signin_token)
    if (signin_token.value != false) _synchro_pending_stamps()
    in_period.value = check_period()
    // router.replace({name: 'home', query: query})
  })
  
  
  
  /*************************************/
  // ローディング表示（子コンポーネントからのイベント捕捉）
  /*************************************/
  
  const is_loading = ref(false)
  let tm = null
  const loading = (bool) => {
    // is_loading.value = bool
    if (bool) {
      tm = setTimeout(() => { is_loading.value = true }, 500)
    }
    else {
      if (tm) clearTimeout(tm)
      is_loading.value = false
    }
  }



  /*************************************/
  // すべての操作を無効化（子コンポーネントからのイベント捕捉）
  /*************************************/
  
  const is_all_disabled = ref(false)
  const all_disabled = (bool) => {
    is_all_disabled.value = bool
  }
  
  
  
  /*************************************/
  // モーダル表示
  /*************************************/
  
  let modal_list = {ModalAgreement, ModalHowto}
  // let modal_list = {ModalHowto}
  const modal_name = ref('ModalHowto')
  const is_visible_modal = ref(false)
  
  //モーダルオープン
  const show_modal = (mn) => {
    if (Object.keys(modal_list).indexOf(mn) === -1) return false
    is_visible_confirm.value = false
    // is_visible_modal.value = false
    modal_name.value = mn
    is_visible_modal.value = true
  }
  
  //モーダルクローズ
  const modal_close = () => {
    is_visible_modal.value = false
    is_visible_confirm.value = false
  }
  
  
  
  /*************************************/
  // サインアウト
  /*************************************/
  
  const is_visible_confirm = ref(false)
  const show_signout_confirm = () => {
    modal_close()
    let stamp = (store.stamps.filter(v => v.pending == true))    //ペンディングフラグが立っているスタンプデータの配列
    if (stamp.length > 0) {
      toast.show('同期していないスタンプデータがあります', { type: 'warning' })
      return false
    }
    else if (store.prize_complete_done_pending) {
      toast.show('同期していないデータがあります', { type: 'warning' })
      return false
    }
    is_visible_confirm.value = true   //confirm モーダルオープン
  }
  const confirm_close = (bool) => { //confirm モーダルで意思確認
    is_visible_confirm.value = false
    if (bool) { //「はい」を選択
      // loading(true)
      signout()
      toast.show('サインアウトしました', { type: 'info', duration: 2000 })
      // router.replace({ name: 'about', query: query })
      // setTimeout( //localStorage書き込み待ち
      //   () => {
      //       router.replace({ name: 'about', query: query })
      //       loading(false)
      //     }, 200
      //   )
    }
  }
  const signout = () => { //サインアウト処理
    loading(true)
    localStorage.removeItem(constant.SIGNIN_TOKEN)
    route.meta.signin_token = false
    signin_token.value = false
    store.$reset()
    document.getElementById('app_wrapper').style.visibility = 'hidden'

    let pg_name = (route.name == 'about') ? 'home' : 'about' 

    setTimeout( //localStorage書き込み待ち
        () => {
            router.replace({ name: pg_name, query: query })
            document.getElementById('app_wrapper').style.visibility = 'visible'
            loading(false)
          }, 1500
    )
  }
  
  
  
  /*************************************/
  // View（コンポーネント）の追加・削除のイベントを捕捉
  /*************************************/
  
  const unmounted = () => {
    modal_close()
    confirm_close()
    nav_open.value = false
    loading(true)
  }

  const w_scroll = ref(0) //フッターの「新規登録・サインイン」ボタンの表示用
  document.addEventListener("scroll", () => {
    if (window) w_scroll.value = window.scrollY
  })

  let regi_pos = ref(99999) //フッターの「新規登録・サインイン」ボタンの表示用
  const mounted = () => {    
    // view_title.value = (route.name != 'about') ? route.meta.title : ''
    view_title.value = ('view_title' in route.meta) ? route.meta.view_title : route.meta.title
    signin_token.value = route.meta.signin_token

    //クエリパラメータ引き回し
    Object.keys(query).filter(v => { delete query[v]} )
    const search_params = new URLSearchParams(window.location.search)
    for(let [k, v] of search_params) query[k] = v
  
    //「×」を押しても、ページ遷移のたびに復活
    is_visible_a2hs.value = ("serviceWorker" in window.navigator) ? true : false
  
    // //ページ遷移時のフッターのズレを解消
    setTimeout(() => { window.scrollTo(0, window.scrollY + 3) }, 10)

    loading(false)

    //AboutView での「「新規登録・サインイン」ボタンの表示・非表示処理
    regi_pos.value = 999999

    if (route.name == 'about' && is_online.value && signin_token.value == false) {
      regi_pos.value = document.getElementById('register').offsetTop - window.innerHeight * 0.4
      window.onload = () => {
        regi_pos.value = document.getElementById('register').offsetTop - window.innerHeight * 0.4
      };
    }

  /*
    let hoge = document.querySelector(".hoge")
    hoge.scrollTop = 1;
  
    document.addEventListener('touchmove', function(e) {
  
      if (e.target === hoge && hoge.scrollTop !== 0 && hoge.scrollTop + hoge.clientHeight !== hoge.scrollHeight) {
        e.stopPropagation();
      }
      else {
        e.preventDefault();
      }
    }, { passive: false })
  
    hoge.addEventListener('scroll', function() {
    if (hoge.scrollTop === 0) {
      hoge.scrollTop = 1;
    }
    else if (hoge.scrollTop + hoge.clientHeight === hoge.scrollHeight) {
      hoge.scrollTop = hoge.scrollTop - 1;
    }
  });
  
    */
  }
  
  
  
  /*************************************/
  // アップデート確認
  /*************************************/
  let is_update_lock = false
  document.addEventListener(constant.SW_UPDATED, (/*e*/) => {
    //発火元は src/resisterServiceWorker.js
    // console.dir(e)
    if (! window.navigator.onLine) return false //念のためオンライン確認
    if (is_update_lock) return false
    is_update_lock = true
  
    let tm = 1
    if (is_standalone) {
      toast.show('最新バージョンに更新します', { type: 'info' })
      tm = 3000
    }
  
    setTimeout(() => {
      //古いサービスワーカーの削除
      // window.navigator.serviceWorker.getRegistrations()
      //   .then(registrations => {
      //     for(let registration of registrations) {
      //       // registration.update()
      //       registration.unregister()
      //     }
      //   })
      window.navigator.serviceWorker.getRegistration()
        // .then(registration => { registration.update() })
        .then(registration => {
          if (registration == undefined) {
            toast.show('更新が失敗しました', {type: 'error'})
            is_update_lock = false
            return false
          }
          registration.unregister().then((bool) => {
            if (bool) {
              setTimeout(() => {
                window.location.reload(true)
              }, 500)
            }
            else {
              toast.show('更新に失敗しました', {type: 'error'})
              is_update_lock = false
            }
          })
          // registration.unregister()
          // setTimeout(() => {
          //   is_update_lock = false
          //   window.location.reload(true)
          // }, 500)
        })
    }, tm)
  // }, {once: true})
  })
  
  // let is_controlled = Boolean(navigator.serviceWorker.controller)
    /*
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.addEventListener('controllerchange', () => {
        if (is_controlled) {
          window.location.reload(true)
        }
        else is_controlled = true
      })
    }
    */
  
  
  
  /*************************************/
  // A2HS（android ホーム画面に追加）
  /*************************************/
  
  //A2HSボタンを表示するか
  const is_visible_a2hs = ref(("serviceWorker" in window.navigator) ? true : false)
  
  const deferred_prompt = ref(null) //退避用
  provide(constant.DEFERRED_PROMPT, deferred_prompt) //android A2HS説明ページで利用
  window.addEventListener('beforeinstallprompt', (e) => {
    //インストール済の場合、このイベントは上がらない
    e.preventDefault()
    deferred_prompt.value = e //退避させてホーム画面追加ボタンを押した時に利用
  })
  
  //ホーム画面追加ボタン押下
  const click_a2hs = () => {
    if (is_standalone) return false   //念のため
  
    if (is_iphone) { //iPhone
      if (Object.keys(modal_list).indexOf('A2hsIphone') === -1) {
        import('@/components/A2hsIphone/A2hsIphone.vue')
          .then((A2hsAndroid) => {
            modal_list['A2hsIphone'] = A2hsAndroid.default
            show_modal('A2hsIphone')
          })
      }
      else show_modal('A2hsIphone')
    }
    else if (deferred_prompt.value != null) { //Android（インストール可能状態） など
      if (Object.keys(modal_list).indexOf('A2hsAndroid') === -1) {
        import('@/components/A2hsAndroid/A2hsAndroid.vue')
          .then((A2hsAndroid) => {
            modal_list['A2hsAndroid'] = A2hsAndroid.default
            show_modal('A2hsAndroid')
          })
      }
      else show_modal('A2hsAndroid')
    }
    else return false //Android（インストール不可能状態） など
  }
 
  
  
  /*************************************/
  // フッター／サインイン・サインアップへ（サインインしていないとき）
  /*************************************/
  
  const siginup_signin = () => {
    modal_close()
    confirm_close()
    nav_open.value = false
    router.push({name: 'about', query: query, hash: '#register'})
  }


  
  /*************************************/
  // シェアボタン
  /*************************************/
  const share = () => {
    if (! navigator.share) {
      toast.show('シェア機能に対応していません', { type: 'warning' })
      return false
    }
    navigator.share({
      title: constant.SHARE.TITLE,
      text: constant.SHARE.TEXT,
      url: constant.SHARE.URL
    })
    .then(() => {
      // toast.show('シェアしました', { type: 'info' })
    })
    .catch((/*error*/) => {
      // シェアせずにウィンドウを閉じた場合も走ってしまう
      // toast.show('シェアに失敗しました', { type: 'error' })
      // console.dir(error)
    })
  }
  

  
  /*************************************/
  //  ナビゲーションメニュー
  /*************************************/
  const nav_open = ref(false)
  // const nav_click = () => { nav_open.value = (! nav_open.value) }
  const nav_click = (e) => {
    nav_open.value = false
    e.stopPropagation()
  }



  /*************************************/
  // その他 操作系
  /*************************************/
  onMounted(() => {
    // バウンススクロール禁止
    // document.addEventListener('touchmove', function(e) {
    //   e.preventDefault()
    // }, { passive: false })
    // init_nav()  //ナビメニューイベント設定
  })
  
  //ピンチアウトによる拡大禁止（2本指操作キャンセル）
  document.addEventListener('touchstart', (e) => {
    if (e.touches.length >= 2) e.preventDefault();
  }, { passive: false })

  document.addEventListener("touchmove", (e) => {
    if (e.touches.length >= 2) e.preventDefault()
  }, { passive: false })

  document.addEventListener("gesturestart", (e) => {
    e.preventDefault();
  })



    
  //ダブルタップによる拡大を無効
  document.addEventListener("dblclick", function(e) {
    e.preventDefault()
  }, { passive: false })


  //バックグラウンドからの復帰
  document.addEventListener("visibilitychange", () => {
    if (document.visibilityState === "visible" && is_online.value) {
      //サーバ通信
      console.dir("server_access")
    }
    // else {
    // }

    // setTimeout(() => { 
      window.scrollTo(0, window.scrollY + 2)
    // }, 5)
  })

  //呼び出し時（初期）
  //サーバ通信
  if (is_online.value) {
   console.dir("server_access")
  }


  // // ブラウザバックを無効化
  // addEventListener("popstate", () => {
  //   router.go(1)
  // })


  //画像キャッシュ（プリロード）
  const images = [
    'stamps/pending.png',
    'stamps/no_stamp.png',
  ]
  images.forEach(v => { new Image().src = 'img/' + v })


  //イベントをモーダルの下に伝播させない
    const stop_event = (e) => {
    e.stopPropagation()
    return false
  }


  //全体エラー補足
// window.addEventListener("error", event => {
//     alert()
//     console.log("Captured in error EventListener", event.error);
//   });
  onErrorCaptured(e => {
      console.dir(e)
      alert("エラーが発生しました。")
      // location.reload()
  });



  
  </script>
  
  <style src="./App.css"></style>
  <style src="./assets/awesome/css/all.css"></style>
  