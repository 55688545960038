<template>
  <div class="home stamp_top"  v-if="route.meta.signin_token != false" v-cloak>
    <!-- <h1>{{ route.meta.title }}</h1> -->
    <div class="stamp_list">
      <!-- <StampThumb v-for="(v, k) in stamps" :key="k" :stamp_id="k + 1" :img="v.img" :title="v.title" :done="v.done" :pending="((! is_online) ? true : v.pending)" @stamp_clicked="stamp_clicked(k + 1)" :class="{'click_disabled': (! is_online)}"></StampThumb> -->
      <StampThumb v-for="(v, k) in stamps" :key="k" :stamp_id="k + 1" :img="v.img" :title="v.title" :done="v.done" :pending="v.pending" @stamp_clicked="stamp_clicked(k + 1)" :class="{'click_disabled': (! is_online)}"></StampThumb>
    </div>


    <div class="stamp_status">
        <div class="stamp_status_box">
          <template v-if="store.isStampCompleted">
            <dl class="stamp_status_main cl_pink">
              <dt class="stamp_text_status">スタンプ コンプリート！</dt>
              <dd class="btn_base_default_mgcancel btn_red_border">
                <template v-if="store.isStampCompleted">
                  <p v-if="store.prize_complete_done" class="stamp_text_end">プレゼント引換済</p>
                  <!-- <button class="btn_middle" v-else @click.prevent="complete_button_clicked" :disabled="! (is_online && (route.meta.signin_token != false) && route.meta.in_term == 0)">プレゼント応募</button> -->
                  
                  <router-link class="btn_half" v-else :to="{name: 'slide', query: query}" :disabled="! (is_online && route.meta.in_term == 0)">プレゼント引換</router-link>
               
                </template>
              </dd>
            </dl>
          </template>
          <template v-if="store.getBingosNum > 0">
            <dl class="stamp_status_main cl_green">
              <dt class="stamp_text_status">ビンゴ <span class="starus_numb">{{ store.getBingosNum }}</span>列 達成！</dt>
              <dd class="btn_base_default_mgcancel  btn_green_border">
                  <p v-if="store.prize_bingo_done" class="stamp_text_end">{{ store.getBingosNum }}口で応募済</p>
                  <button class="btn_half" v-else @click.prevent="bingo_button_clicked" :disabled="! (is_online && route.meta.in_term == 0)">プレゼント応募</button>
              </dd>
            </dl>
          </template>
        </div>
    </div>

    <!--
    <template v-if="route.meta.signin_token != false">
      <router-link :to="{name: 'qr'}">QRコード読取</router-link><br>
    </template>
    -->

    <div class="btn_base_single btn_salmon">
      <router-link :disabled="! is_online" :style="{'pointer-events': ((! is_online) ? 'none' : 'auto')}" :to="{name: 'map', query: query}" class="btn_half"><i class="fa-solid fa-location-dot"></i>地 図</router-link>
    </div>
    <!--
    <div class="btn_base_single btn_default">
      <a href="#" @click.prevent="$emit('howto')"><i data-v-f7d8f62a="" class="fa-solid fa-question"></i>利用方法</a>
    </div>
    -->
  </div>
</template>



<script setup>
import StampThumb from '@/components/StampThumb/StampThumb'
import { defineEmits, onMounted, onUnmounted, /* onBeforeMount,*/ inject } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from '@/stores/store.js'
import { storeToRefs } from 'pinia'
import constant from '@/constant'

const router = useRouter()
const route = useRoute()

const store = useStore()
const { stamps } = storeToRefs(store)

//TEST//
// store.prize_complete_done = true
// store.prize_bingo_done= true


//クエリパラメータ引き回し
const search_params = new URLSearchParams(window.location.search)
const query = {}
for(let [k, v] of search_params) query[k] = v


const emit = defineEmits(["mounted", "unmounted", "signout", "signout_force"])
onMounted(() => { emit("mounted") })
onUnmounted(() => { emit("unmounted") })



//エラー系
//LS に signin_token が存在するのに（サインイン状態）、スタンプデータが存在しない（再ログイン）
if (route.meta.signin_token != false && store.stamps.length == 0) {
  //ログインデータ取得
  emit("loading", true) //ローディング表示
  const promise = store.signIn(route.meta.signin_token)
  promise.then(function(res) {
    // console.dir(res)
    emit("loading", false) //ローディング表示
    if (res == false) {
      // toast.show('エラーが発生しました', { type: 'error'})
      // throw "Signin Error"
      emit("signout_force")
      router.replace({name: 'about', query: query})
    }
    else {
      window.location.reload(true)
    }
  })
}


//オフライン時クリック不可
const is_online = inject(constant.IS_ONLINE)
if (is_online == undefined)  throw new Error('provide/inject Error.')


/*
//ログインしていなければ「概要」概要ページへリダイレクト
onBeforeMount(
  () => {
    if (route.meta.signin_token == false) {
      router.replace({name: 'about', query: query})
    }
  }
)
*/


//スタンプサムネールをクリックして詳細ページへ遷移
const  stamp_clicked = (stamp_id) => {
  // if (! is_online.value) return false
  router.push({name: 'stamp', params: {stamp_id: stamp_id}, query: query})
}

//ビンゴ達成応募ボタン
const bingo_button_clicked = () => {
  let url = new URL(constant.BINGO_FORM, location.href)
  const q = Object.entries(query)
  for(const [k, v] of q) url.searchParams.set(k, v)
  url.searchParams.set('signin_token', route.meta.signin_token)
  window.location.assign(url.href)
}

//スタンプコンプリート 応募ボタン
/*
const complete_button_clicked = () => {
  let url = new URL(constant.COMPLETE_FORM, location.href)
  const q = Object.entries(query)
  for(const [k, v] of q) url.searchParams.set(k, v)
  url.searchParams.set('signin_token', route.meta.signin_token)
  window.location.assign(url.href)
}
*/





</script>


<style scoped src="./HomeView.css"></style>

