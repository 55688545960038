<template>
  <div class="qr_notice" v-cloak>
    <!-- <h1>{{ route.meta.title }}</h1> -->
    <div class="qr_attention">
      <i class="fa-solid fa-triangle-exclamation"></i>
    </div>
    <p class="textcenter">
      次の画面で「カメラへのアクセス」を<br>「許可」してください。
    </p>
    <div class="btn_base_single btn_green">
      <!-- <router-link :to="{name: 'qr', query: query}">QRコードを読み取る</router-link> -->
      <a href="#" class="btn_half" @click.prevent="goto_qr">QRコードを読み取る</a>
    </div>
    <div class="btn_base_default square_gray btn_blackgray_border">
      <router-link class="btn_half" :to="{name: 'home', query: query}">キャンセル</router-link>
      <!-- <a href="#" class="btn_half" @click.prevent="cancel">キャンセル</a> -->
      <!-- <a href="#" @click.prevent="router.back()">戻る</a> -->
  </div>

  </div>
</template>



<script setup>
import { inject, defineEmits, onMounted, onUnmounted } from 'vue'
import constant from '@/constant'
import { useRouter } from 'vue-router'

const router = useRouter()

//クエリパラメータ引き回し
const search_params = new URLSearchParams(window.location.search)
const query = {}
for(let [k, v] of search_params) query[k] = v

if (constant.GPS_VERSION) router.replace({name: 'home', query: query})


//Qrnotice 表示
const show_notice = inject(constant.SHOW_NOTICE)
// show_notice.value = false


// //キャンセルボタン
// const cancel = () => {
//   show_notice.value = true
//   router.replace({name: 'home', query: query});
// }

const goto_qr = () => {
  show_notice.value = false
  router.replace({name: 'qr', query: query});
}


const emit = defineEmits(["mounted", "unmounted"])
onMounted(() => { emit("mounted") })
onUnmounted(() => { emit("unmounted") })
</script>


<style scoped src="./QrnoticeView.css"></style>
