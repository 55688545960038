<template>
    <div class="confirm_win" v-cloak @click.stop="stop_event" @touchstart.stop="stop_event" @touchmove.stop="stop_event" @touchend.stop="stop_event">
      <div class="confirm_win_bg"></div>
      <div class="confirm_win_fg">
        <p>
          <slot>確認してください</slot>
        </p>
        <button @click.prevent="$emit('confirm_close', true)">はい</button>
        <button @click.prevent="$emit('confirm_close', false)">いいえ</button>
      </div>
    </div>
</template>



<script setup>
  //イベントをモーダルの下に伝播させない
  const stop_event = (e) => {
    e.stopPropagation()
    return false
  }
</script>



<style scoped src="./ConfirmWin.css"></style>
