<template>
  <div class="qr" v-cloak>
  <div v-show="is_show">
    <!-- <h1>{{ route.meta.title }}</h1> -->
    <div class="details_matter_wrap">
      <p> QRコードが枠内に入るように写してください<!--<span class="min_mark">。</span>--></p>
      <div class="camera">
        <video v-if="is_alive" id="camera_inner" class="reader-video" autoplay playsinline></video>
        <div v-if="is_alive_frame" class="qr_frame"></div>
      </div>
    </div>
    <div class="btn_base_default square_gray btn_blackgray_border">
      <router-link class="btn_half" :to="{name: 'home', query: query}">キャンセル</router-link>
    </div>
  </div>
  </div>
</template>



<script setup>
import { defineEmits, onMounted, onUnmounted, inject, ref } from 'vue'
// import { defineEmits, onMounted, onUnmounted, inject} from 'vue'
import { useStore } from '@/stores/store'
import { /*onBeforeRouteUpdate,*/ useRouter, useRoute } from 'vue-router'
// import { QrcodeStream } from 'vue-qrcode-reader'
import constant from '@/constant'
import jsQR from "jsqr";

const store = useStore()
const router = useRouter()
const route = useRoute()

const is_show = ref(false)
const is_alive = ref(true)
const is_alive_frame = ref(false)


//クエリパラメータ引き回し
const search_params = new URLSearchParams(window.location.search)
const query = {}
for(let [k, v] of search_params) query[k] = v


if (constant.GPS_VERSION) router.replace({name: 'gps', query: query})

//Qrnotice 表示
const show_notice = inject(constant.SHOW_NOTICE)
if (show_notice.value) router.replace({name: 'qr_notice', query: query})


//Alert表示用
const toast = inject('toast');

//HOME起動か否か
const is_standalone = inject(constant.IS_STANDALONE)


//新規獲得スタンプ表示用
const new_stamp = inject(constant.NEW_STAMP)
if (! new_stamp)  throw new Error('provide/inject Error.')


//サインイン状態
if (route.meta.signin_token == false) throw new Error('auth Error.')
const signin_token = route.meta.signin_token


/////////////////////////////////////////////////////////////
let stream;

const play_video = (video) => {

  navigator.mediaDevices
    .getUserMedia({
        audio: false,
        video: {
          facingMode: 'environment'
            // facingMode: {
            //     exact: 'environment'
            // }
        }
    })
    .then(function(stream_) {
        stream = stream_
        video.srcObject = stream
        video.onloadedmetadata = function() {
            video.play()
            is_alive_frame.value = true
            emit("loading", false)
            emit("all_disabled", false)
        }
    })
    .catch(function(e) {
        _error(e)
    })

}


const get_image = (video) => {
  const canvas = document.createElement("canvas");
// canv.height = this.height;
// canv.width = this.width;
  const ctx = canvas.getContext('2d', {willReadFrequently: true})

  const detect_qr = () => {
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height)
      const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height)
      const cd = jsQR(imageData.data, canvas.width, canvas.height)
      if (cd) { //OK
        _analyze_code(cd.data)
      } else {  // 失敗したら再度実行
          setTimeout(() => { detect_qr() }, 25)  //負荷軽減に一定間隔で
      }
  }
  detect_qr()
}


const _analyze_code = (code) => {
    code = code.replace(/^s+|s+$/g, '').replace(/\/+$/, '').replace(/^.+\//, '')
    if (code.match(/[^0-9a-zA-Z]/)) {
      // toast.show('QRコードをご確認ください', { type: 'error', onClose: function() { router.replace({name: 'home', query: query}) }})
      toast.show('QRコードをご確認ください', { type: 'error' })
      router.replace({name: 'home', query: query})
    }
    else {
      const stamp = store.getStampByCode(code)
      if (stamp === false) {
        toast.show('読み取りエラーです', { type: 'error' })
        router.replace({name: 'home'})
      }
      else if (stamp.done) {
        toast.show('獲得済スタンプです', {type: "info"}) 
        router.push({name: 'stamp', params: {stamp_id: stamp.stamp_id}, query: query})
      }
      else {
        //QR読み取りは一定間隔をおく必要がある
        let sa = 999999
        if (store.last_update != null) {
          const now = new Date()
          sa = now.getTime() - (new Date(store.last_update)).getTime()
          sa /= 1000
        }
        if (sa <= constant.PAST_TIME) {
          // toast.show('連続して読み取れません', {type: "warning", onClose: function() { router.replace({name: 'home', query: query}) }}) 
          router.replace({name: 'home', query: query})
          toast.show('連続して読み取れません', {type: "warning"}) 
        }
        else {
          //スタンプ押下
          emit("loading", true) //ローディング表示
          emit("all_disabled", true)
          const promise = store.stampIt(signin_token, code) //store 同期
          promise.then(function(res) {
            if (res == false) {
              // toast.show('スタンプが保存できません', { type: 'error', onClose: function() { router.replace({name: 'home', query: query}) }})
              router.replace({name: 'home', query: query})
              toast.show('スタンプが保存できません', {type: "error"}) 
            }
            else {
              if (('invalid_token' in res) && res.invalid_token == true) {
                toast.show('他の端末でサインインされました', {type: "error"}) 
                setTimeout(() => { emit("signout_force") }, 5000)
              }
              else {  //OK
                if (store.isStampCompleted) toast.show('スタンプ コンプリート！', { type: 'error', duration: 5000, onClick: () => { router.replace({name: 'slide', query: query}) }})

                //新規獲得スタンプ表示用（provide/inject）
                new_stamp.stamp_id = stamp.stamp_id
                new_stamp.is_new = true

                emit("loading", false) //ローディング表示
                emit("all_disabled", false)
                // if (("pending" in res) && res["pending"]) { //オフライン
                //   //ホームへ
                //   router.push({name: 'home', query: query});
                // }
                // else {
                  //スタンプ詳細ページへ
                  router.push({name: 'stamp', params: {stamp_id: stamp.stamp_id}, query: query});
                // }
              }
            }
          })  //promise
        }
      }
    }
}


const _error = (e) => {
  // stream.getTracks().forEach(track => track.stop());  //カメラデバイス終了
  is_alive.value = false
  is_alive_frame.value = false
  if (e.name == 'NotAllowedError') {
  // if (e && e.match(/^NotAllowedError/)) {
    //"ERROR: you need to grant camera access permisson"
    toast.show('カメラへのアクセスを許可してください', { type: 'warning'})
    // if (window.navigator.onLine) {  //オンライン
      // setTimeout(() => { window.location.reload(true) }, 3000)
      // setTimeout(() => {
      //   let url = router.resolve({ name: 'qr' }).href + window.location.search
      //   window.location.assign(location.origin + url);
      // }, 3000)
    // }
    // else {
    //     router.replace({name: 'home', query: query})
    // }
  }
  else if (e.name == 'NotFoundError') {
    //"ERROR: no camera on this device"
    toast.show('カメラ装置が見つかりません', { type: 'warning'})
    // setTimeout(() => { router.replace({name: 'home', query: query}) }, 3000)
  }
  else if (e.name == 'NotSupportedError') {
    //"ERROR: secure context required (HTTPS, localhost)"
    toast.show('通信エラーが発生しました', { type: 'error'})
    // setTimeout(() => { router.replace({name: 'home', query: query}) }, 3000)
  }
  else if (e.name == 'NotReadableError') {
    //"ERROR: is the camera already in use?"
    toast.show('カメラが他のアプリで使用中です', { type: 'warning'})
    // setTimeout(() => { router.replace({name: 'home', query: query}) }, 3000)
  }
  else if (e.name == 'OverconstrainedError') {
  //"ERROR: installed cameras are not suitable"
    toast.show('ご利用になれないカメラ装置です', { type: 'error'})
    // setTimeout(() => { router.replace({name: 'home', query: query}) }, 3000)
  }
  else if (e.name == 'StreamApiNotSupportedError') {
    //"ERROR: Stream API is not supported in this browser"
    toast.show('QRコード読み取りをご利用になれないブラウザです', { type: 'error'})
    // setTimeout(() => { router.replace({name: 'home', query: query}) }, 3000)
  }
  else if (e.name == 'StreamLoadTimeoutError') {
    if (is_standalone && (/iPad|iPhone|iPod/.test(navigator.userAgent))) {
      const device = (/iPad/.test(navigator.userAgent)) ? 'iPad' : 'iPhone'
      toast.show(device + 'の再起動が必要です', { type: 'error', duration: 5000})
    }
    else {
      toast.show('タイムアウトしました', { type: 'error'})
    }
    // setTimeout(() => { router.replace({name: 'home', query: query}) }, 3000)
  }
  else {
    toast.show('エラーが発生しました', { type: 'error'})
    // setTimeout(() => { router.replace({name: 'home', query: query}) }, 3000)
  }
  show_notice.value = true
  setTimeout(() => {
    // emit("loading", false)
    // router.replace({name: 'home', query: query})
    window.location.assign(location.origin + route.fullPath)
  }, 3000)
}


//カメラ起動
const dom_rendered = () => {
  is_show.value = true
  const video  = document.querySelector('#camera_inner')
  // console.dir(video)
  // console.dir(play_video)
  // console.dir(get_image)
  play_video(video)
  get_image(video)
}


const emit = defineEmits(["mounted", "unmounted", "loading", "all_disabled"])
onMounted(() => {
  emit("mounted") 
  if (show_notice.value) return
  setTimeout(() => { dom_rendered(); }, 1000) //前のカメラが終了していない場合を見越して、遅れて起動
  emit("loading", true) //カメラの準備が整うまでローディング表示継続
  emit("all_disabled", true)
})
onUnmounted(() => {
  if (stream) stream.getTracks().forEach(track => track.stop());  //カメラデバイス終了
  is_alive.value = false
  is_alive_frame.value = false
  emit("unmounted")
})

</script>



<style scoped src="./QrView.css"></style>
