<template>
  <div class="offline" v-cloak>
    <!-- <h1>インターネット接続がありません</h1> -->
    <template v-if="route.meta.signin_token == false">
      <h3 class="offline_ttl">インターネット接続がありません</h3>
      <p class="offline_desc">ご利用にはインターネットに<br>接続した状態でサインインしてください</p> 
      
    </template>
    <template v-else>
      <p>スタンプの獲得（QRコード読取）は<br>オフラインでもご利用になれます。</p>
      <div class="btn_base_single btn_blackgray_border">
      <router-link class="offline_btn btn_middle" :to="{name: 'home', query: query}">スタンプ帳</router-link></div>
    </template>
  </div>
</template>



<script setup>
import { defineEmits, onMounted, onUnmounted } from 'vue'
import { useRoute } from 'vue-router'

const route = useRoute()

//クエリパラメータ引き回し
const search_params = new URLSearchParams(window.location.search)
const query = {}
for(let [k, v] of search_params) query[k] = v

const emit = defineEmits(["mounted", "unmounted"])
onMounted(() => { emit("mounted") })
onUnmounted(() => { emit("unmounted") })
</script>



<style scoped src="./OfflineView.css"></style>
