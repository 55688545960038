<template>
  <div class="howto" id="howto" v-cloak @click.stop="stop_event" @touchstart.stop="stop_event" @touchmove.stop="stop_event" @touchend.stop="stop_event">
    <!-- <h2 class="modal_title">利用方法</h2> -->

    <div class="modal_howto">

        <h2 class="howto_ttl">簡単操作で<br>
            いつも持ち歩いているスマホに<br>
            スタンプが集められる！
        </h2>
        <div class="btn_base_default">
        <a class="howto_how_btn" @click.prevent="anchor_scroll('how')"><p>ご利用方法</p></a>
        <a class="howto_att_btn" @click.prevent="anchor_scroll('attention')"><p>ご注意</p></a></div>

        <div class="how_area" id="how">
          <h2 class="how_area_ttl">ご利用方法</h2>
          <ol>
            <li class="how_list">
              <div class="how_item">
              <h3 class="how_item_ttl">まずは新規登録</h3>
              <p class="how_item_desc">フォームに必要事項を入力して<!--登録を完了して-->ください。<!--<br>-->登録にはインターネット接続が必要です。<br>本人確認に、メールアドレスまたはLINE認証がご利用になれます。どちらか一方をお選びください。
              </p>
              <p class="how_item_attend">※両方で登録することはできません。</p>
              <div class="btn_base_default how_btn" v-if="is_online && route.meta.signin_token == false">
                <!-- <router-link :to="{name: 'signup', query: query}">新規登録はこちら</router-link> -->
                <a href="#" @click.prevent="linkto('signin')">新規登録はこちら</a>
              </div>
              </div>
              <img src="../../assets/images/modal_howto/triangle.png" alt="下矢印">
            </li>
            <li class="how_list">
              <div class="how_item">
              <h3 class="how_item_ttl">スタンプスポットにGO!</h3>
              <p class="how_item_desc">地図を頼りにスタンプスポットを見つけてください。<br>スタンプスポットを周る順番に決まりはありません。実施期間内であれば、何日かに分けて周ってもOK。

              </p>
              <div class="btn_base_default how_btn" v-if="is_online">
                <!-- <router-link :to="{name: 'map', query: query}" :disabled="! is_online" :style="{'pointer-events': ((! is_online) ? 'none' : 'auto')}">地図はこちら</router-link> -->
                <a href="#" @click.prevent="linkto('map')" :disabled="! is_online" :style="{'pointer-events': ((! is_online) ? 'none' : 'auto')}">地図はこちら</a>
              </div>
              </div>
              <img src="../../assets/images/modal_howto/triangle.png" alt="下矢印">

            </li>
            <li v-if="constant.GPS_VERSION" class="how_list">
              <div class="how_item stamp_illust">
                <h3 class="how_item_ttl">スマホの位置情報からスタンプGET!</h3>
                <p class="how_item_desc">メニューの「スタンプ獲得」を選ぶとスマホの位置情報を取得します。<br>スタンプスポットにいることが確認されるとスタンプの絵柄がスマホに表示されます。<!--「スタンプ帳」メニューからいつでもコレクションしたスタンプを確認できます。--></p>
              </div>
              <img src="../../assets/images/modal_howto/triangle.png" alt="下矢印">
            </li>
            <li v-else class="how_list">
              <div class="how_item stamp_illust">
                <h3 class="how_item_ttl">QRコードを読み取ってスタンプGET!</h3>
                <p class="how_item_desc">スタンプスポットにあるQRコードを読み取ってください。<!--<br>メニューの「QRコード読取」を選ぶとカメラが起動します。--><br>QRコードの読み込みに成功するとスタンプの絵柄がスマホに表示されます。<!--「スタンプ帳」メニューからいつでもコレクションしたスタンプを確認できます。--></p>
                <ul class="how_item_attend">
                  <li>※「スタンプ帳」メニューからいつでもコレクションしたスタンプを確認できます。</li>
                </ul>
              </div>
              <img src="../../assets/images/modal_howto/triangle.png" alt="下矢印">
            </li>
            <li class="how_list">
              <div class="how_item stamp_present">
              <h3 class="how_item_ttl">スタンプを全部集めたらプレゼント</h3>
              <p class="how_item_desc">スタンプがすべて集まったスタンプ帳を「プレゼント交換場所」のスタッフに見せると、プレゼントを差し上げます。<!--プレゼントは先着順で数に限りがございますのでご注意ください。-->
              </p>
              <ul class="how_item_attend">
                <li>※プレゼントは先着順で数に限りがございますのでご注意ください。</li>
              </ul>
            </div>
              <img src="../../assets/images/modal_howto/triangle.png" alt="下矢印">

            </li>
            <li class="how_list">
              <div class="how_item stamp_bingo">
              <h3 class="how_item_ttl">ビンゴ賞にご応募ください</h3>
              <p class="how_item_desc">スタンプがすべて集まらなくても、<br>縦横ななめのいずれかの列でスタンプが集まったら、揃った列の数を応募口数としてビンゴ賞プレゼントに応募できます。<!--スタンプ帳の応募ボタンから応募フォームにお進みください。-->
              </p>
            
              <!-- <p class="how_item_attend">※スタンプラリー実施期間終了後に抽選を行い、当選者には賞品をお送りします。<br>
                ※スタンプをすべて集めた方も同時にご応募いただけます。<br>
                ※当選の発表は賞品の発送をもって代えさせていただきます。<br>
                ※抽選結果のお問い合わせにはお答えできません。</p> -->

              <ul class="how_item_attend">
                <li>※スタンプラリー実施期間終了後に抽選を行い、当選者には賞品をお送りします。</li>
                <li>※スタンプをすべて集めた方も同時にご応募いただけます。</li>
                <li>※当選の発表は賞品の発送をもって代えさせていただきます。</li>
                <li>※抽選結果のお問い合せにはお答えできません。</li>
              </ul>

              </div>
            </li>
          </ol>
      </div>


      <div class="att_area" id="attention">
        <h2 class="att_area_ttl">ご注意</h2>
        <ul>
          <li class="att_list">
            <div class="border"></div>
            <h3 class="att_list_ttl">スマホ専用ページです</h3>
            <p>iPhone または Android端末でご参加ください。パソコンでは参加できません。
            </p>
          </li>
          
          <li class="att_list">
            <div class="border border2"></div>
            <h3 class="att_list_ttl">お一人様ごとにスマホ１台での<br>ご参加になります</h3>
            <p>複数人でスマホを共有されたり、複数のスマホのスタンプを合算することはできません。
            </p>
          </li>

          <li class="att_list">
            <div class="border"></div>
            <h3 class="att_list_ttl">推奨のブラウザでご利用ください</h3>
            <p>iPhoneでは Safari、Androidでは Chrome が推奨ブラウザです。それぞれ最新のバージョンをお使いください。<br>その他のブラウザではスタンプが貯められない場合があります。ご注意ください。<br>
              また、SNSの投稿のリンクをクリックして表示した場合(SNS内のページ表示)も、スタンプが貯められない場合があります。
            </p>

            <div class="att_list_box">
              <div class="att_list_icon">
                <img src="../../assets/images/modal_howto/safari_icon.png" alt="safariアイコン">
                <div class="att_list_box_name"><p><span>iPhone</span>Safari</p></div>
              </div>
              <p class="or">OR</p>
              <div class="att_list_icon">
                <img src="../../assets/images/modal_howto/chrome_icon.png" alt="chromeアイコン">
                <div class="att_list_box_name"><p><span>Android</span>Chrome</p></div>
              </div>
            </div>
            <div>
              <template v-if="device_alert == 'safari'"> 
                <div class="howto_alert">
                  <p><span>Safari</span>で開きなおしてください</p><button class="copy_btn" v-if="is_clipbord_available" @click.prevent="clipbord"><span>URLをコピーする</span></button>
                </div>
              </template> 
              <template v-else-if="device_alert == 'chrome'"> 
                <div class="howto_alert">
                  <p><span>Chrome</span>で開きなおしてください</p><button class="copy_btn" v-if="is_clipbord_available" @click.prevent="clipbord"><span>URLをコピーする</span></button>
                </div>
              </template>
              <template v-else-if="device_alert == 'other'">
                <div class="howto_alert">
                  <p><span>iPhone</span>または<span>Android</span>端末でご利用ください。</p>
                </div>
              </template>
            </div> 
          </li>

          <li class="att_list">
            <div class="border border3"></div>
            <h3 class="att_list_ttl">シークレットモード、<br>プライベートブラウズモードは<br>利用できません</h3>
            <p>シークレットモード、プライベートブラウズモードは解除してください。スタンプが貯められない場合があります。また本ページでのカメラの利用を許可してください。
            </p>
            <div class="att_list_box">
              <div class="att_list_icon cross_icon">
                <img src="../../assets/images/modal_howto/secret_icon.png" alt="シークレットアイコン">
              </div>
              <div class="att_list_icon cross_icon">
                <img src="../../assets/images/modal_howto/private_icon.png" alt="プライベートアイコン">
              </div>
            </div>
          </li>

          <li class="att_list">
            <div class="border border2"></div>
            <h3 class="att_list_ttl">新規登録はLINEか<br>メールアドレスのどちらか一方で</h3>
            <p>LINEとメールアドレスと両方で登録されると、スタンプがそれぞれのアカウントに分かれて蓄積されます。<br>複数のアカウントのスタンプを合算することはできません
            </p>

            <div class="att_list_box">
              <div class="att_list_icon line_icon">
                <img src="../../assets/images/modal_howto/line_icon.png" alt="LINEアイコン">
                <div class="att_list_box_name"><p>LINE</p></div>
              </div>
              <p class="or">OR</p>
              <div class="att_list_icon mail_icon">
                <img src="../../assets/images/modal_howto/mail_icon.png" alt="mailアイコン">
                <div class="att_list_box_name"><p>Mail</p></div>
              </div>
            </div>
          </li>

          <li v-if="! constant.GPS_VERSION" class="att_list">
            <div class="border border2"></div>
            <h3 class="att_list_ttl">カメラアプリや<br>QRコードリーダーは利用できません</h3>
            <p>QRコードを、カメラアプリやQRコードリーダーアプリ、コードスキャナーで読み込んでもスタンプは貯まりません。<br>必ず本ページにアクセスのうえ「QRコード読取」ボタンを押してスタンプスポットのQRコードを読み込むようにしてください。

            </p>
            <div class="att_list_box">
              <div class="att_list_icon cross_icon">
                <img src="../../assets/images/modal_howto/camera_icon.png" alt="カメラアイコン">
              </div>
              <div class="att_list_icon cross_icon">
                <img src="../../assets/images/modal_howto/qr_icon.png" alt="QRコードアイコン">
              </div>
            </div>
          </li>

          <li v-if="constant.GPS_VERSION" class="att_list">
            <div class="border border2"></div>
            <h3 class="att_list_ttl">通信状態が悪い環境では<br>ページを閉じないでください</h3>
            <p>インターネット接続環境が不安定な場合、<br>位置情報の取得後にスタンプの絵柄が表示されるまではサインアウトしたり、本ページを閉じたりしないようにしてください。
              スタンプが貯められない場合があります。<br>スタンプ画像が表示されない場合は、再度「スタンプ獲得」ボタンを押してください。
            </p>
          </li>
          <li v-else class="att_list">
            <div class="border border2"></div>
            <h3 class="att_list_ttl">通信状態が悪い環境では<br>ページを閉じないでください</h3>
            <p>インターネット接続環境が不安定な場合、<br>QRコードを読み取り後、スタンプの絵柄が表示されるまではサインアウトしたり、本ページを閉じたりしないようにしてください。
              スタンプが貯められない場合があります。<br>スタンプ画像が表示されない場合は、再度QRコードを読み取ってださい。
            </p>
          </li>
          <li class="att_list">
            <div class="border border2"></div>
            <h3 class="att_list_ttl">通信にかかる費用は<br>ご利用者様のご負担となります</h3>
            <p>本ページのご利用にはインターネット接続が必要です。通信料ほか費用はご利用者様のご負担となりますので予めご了承ください。
            </p>
          </li>

          <li class="att_list">
            <div class="border border2"></div>
            <h3 class="att_list_ttl">スタンプスポットは有料施設内に<br>設置されている場合があります</h3>
            <p>スタンプスポットは、有料施設内に設置されている場合があります。あらかじめご了承ください。また、スタンプスポットが設置されている施設の開場時間外に立ち入らないようにしてください。
            </p>
          </li>

          <li class="att_list">
            <div class="border border2"></div>
            <h3 class="att_list_ttl">近隣の迷惑にならないよう<br>ご参加ください</h3>
            <p>スタンプスポット周辺では騒音等（話し声、笑い声など）にご注意いただき、近隣への迷惑行為に該当する行為を行わないようご配慮ください。<br>立ち入りが禁止されているエリアや私有地には、立ち入らないようにしてください。<br>また、ゴミはお持ち帰りください。<br>その他、現地のルールに従ってください。
            </p>
          </li>

          <li class="att_list">
            <div class="border border2"></div>
            <h3 class="att_list_ttl">交通安全にご留意いただき<br>迷惑駐車はご遠慮ください</h3>
            <p>スタンプスポットまでは、ご利用の交通機関のルールに従ってください。<br>お車、バイク、自転車等を利用される場合は必ず駐車場をご利用いただき、迷惑駐車はおやめください。
            </p>
          </li>

          <li class="att_list">
            <div class="border border2"></div>
            <h3 class="att_list_ttl">違反・不正行為が発覚した場合<br>参加は無効になります</h3>
            <p>本施策の想定以外の方法でスタンプを集めた場合、SNS等で獲得したスタンプ画像を配布した場合、その他利用規約に反する行為を行なった場合は参加は無効となります。利用規約をご確認の上、同意いただける場合のみご参加ください。</p>
            <div class="btn_base_default btn_left">
            <a class="btn_kiyaku" href="#" @click.prevent="$emit('show_modal', 'ModalAgreement')">利用規約</a>
          </div>
          </li>
        </ul>
      </div>
    </div>

    <div class="btn_base_default">
        <a class="howto_how_btn" @click.prevent="anchor_scroll('how')"><p>ご利用方法</p></a>
        <a class="howto_att_btn" @click.prevent="anchor_scroll('attention')"><p>ご注意</p></a></div>
<div class="btn_top">
    <a @click.prevent="anchor_scroll('howto')">
      <div class="arrow"></div>
      <p>TOP</p>
    </a></div>
  </div>
</template>


<script setup>
import { ref, inject, defineEmits, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import constant from '@/constant'


const route = useRoute()
const router = useRouter()

const emit = defineEmits(['modal_close'])

const is_online = inject(constant.IS_ONLINE)

//Alert表示用
const toast = inject('toast');

const device_alert = ref('')
const is_clipbord_available = navigator.clipboard

//開いているブラウザがsafari か判定
const is_safari = (() => {
  let ua = window.navigator.userAgent.toLowerCase()
  return (ua.indexOf("safari") != -1 && ua.indexOf("crios") == -1 && ua.indexOf("fxios") == -1) ? true : false
})()

//開いているブラウザがchrome か判定
const is_chrome = (() => {
  let ua = window.navigator.userAgent.toLowerCase()
  return (ua.indexOf("chrome") != -1) ? true : false
})()

if (/iPad|iPhone|iPod/.test(navigator.userAgent)) { //iOS
  if (! is_safari) device_alert.value = 'safari'
}
else if (navigator.userAgent.indexOf('Android') > 0) {  //Android
  if (! is_chrome) device_alert.value = 'chrome'
}
else {
  device_alert.value = 'other'
}


const clipbord = () => {
  if (navigator.clipboard) {
    navigator.clipboard.writeText(location.href)
      .then(function () {
        toast.show('コピーしました', { type: 'info' })
      })
  }
}


const anchor_scroll = (id) => {
  document.getElementById(id).scrollIntoView({
    behavior: 'smooth',
    block: 'start'
  })
}


//オフラインでクローズ
watch(is_online, (to) => {
  if (! to) emit("modal_close")
})


//イベントをモーダルの下に伝播させない
const stop_event = (e) => {
  e.stopPropagation()
  return false
}


//クエリパラメータ引き回し
const search_params = new URLSearchParams(window.location.search)
const query = {}
for(let [k, v] of search_params) query[k] = v


function linkto(pg_name) {
  if (route.name == pg_name) {
    emit("modal_close")
  }
  else {
    router.push({name: pg_name, query: query})
  }
  return false
}




</script>



<style scoped src="./ModalHowto.css"></style>

