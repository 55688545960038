import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '@/views/HomeView/HomeView.vue'
import QrView from '@/views/QrView/QrView.vue'
import QrnoticeView from '@/views/QrnoticeView/QrnoticeView.vue'
import AboutView from '@/views/AboutView/AboutView.vue'
import StampView from '@/views/StampView/StampView.vue'
import OfflineView from '@/views/OfflineView/OfflineView.vue'
// import AgreementView from '@/views/AgreementView/AgreementView.vue'
import constant from '@/constant'


// import { createToaster } from "@meforma/vue-toaster";


// //オフラインWARNING表示用
// const toaster = createToaster({
//   duration: 3000, position: 'top', type: 'warning', maxToasts: 1
// });
// let is_show_toast = false  //WARNING が２つ出ないように制御


const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      title: 'STAMP BOOK',
      view_title: 'スタンプ帳',
      requiresAuth: true,
      canShowOffline: true,
      forceRefresh: true,
      a2hs: true
    }
  },
  {
    path: '/about',
    name: 'about',
    // component: () => import('@/views/AboutView/AboutView.vue'),
    component: AboutView,
    meta: {
      title: 'HOME',
      view_title: '',
      a2hs: true
    }
  },
  {
    path: '/about/:path(.*)',
    name: 'about_redirect',
    redirect: { name: 'about' },
  },
  {
    path: '/map',
    name: 'map',
    component: () => import('@/views/MapView/MapView.vue'),
    meta: {
      title: '地図',
    }
  },
  {
    path: '/qr',
    name: 'qr',
    // component: () => import('@/views/QrView/QrView.vue'),
    component: QrView,
    meta: {
      title: 'QRコード読取',
      canShowOffline: true,
      requiresAuth: true,
    },
  },
  {
    path: '/qr/:random(\\d{1,})',
    name: 'qr_',
    component: () => import('@/views/QrView/QrView.vue'),
    // component: QrView,
    meta: {
      title: 'QRコード読取',
      canShowOffline: true,
      requiresAuth: true,
    },
  },
  {
    path: '/qr_notice',
    name: 'qr_notice',
    component: QrnoticeView,
    meta: {
      title: 'QRコード読取',
      canShowOffline: true,
      requiresAuth: true,
    },
  },
  {
    path: '/gps',
    name: 'gps',
    component: () => import('@/views/GpsView/GpsView.vue'),
    meta: {
      title: 'スタンプ獲得',
      requiresAuth: true,
    },
  },
  {
    path: '/gps_notice',
    name: 'gps_notice',
    component: () => import('@/views/GpsnoticeView/GpsnoticeView.vue'),
    meta: {
      title: 'スタンプ獲得',
      requiresAuth: true,
    },
  },
  {
    path: '/stamp/:stamp_id(\\d{1,})',
    name: 'stamp',
    // component: () => import('@/views/StampView/StampView.vue'),
    component: StampView,
    meta: {
      title: 'スタンプ',
      canShowOffline: true,
      requiresAuth: true
    },
  },
  {
    path: '/stamp',
    name: 'stamp_redirect',
    canShowOffline: true,
    redirect: { name: 'home' },
  },

  {
    path: '/slide',
    name: 'slide',
    component: () => import('@/views/SlideView/SlideView.vue'),
    meta: {
      title: 'プレゼント引換',
      requiresAuth: true,
      // cantSeeOutOfTerm: true
    }
  },

  {
    path: '/signup',
    name: 'signup',
    component: () => import('@/views/SignupView/SignupView.vue'),
    meta: {
      title: '新規登録',
      noShowAfterAuth: true
    }
  },
  {
    path: '/signin',
    name: 'signin',
    component: () => import('@/views/SigninView/SigninView.vue'),
    meta: {
      title: 'サインイン',
      noShowAfterAuth: true
    }
  },
  {
    path: '/prizeexchange',
    name: 'prizeexchange',
    component: () => import('@/views/PrizeexchangeView/PrizeexchangeView.vue'),
    meta: {
      title: 'プレゼント引換場所',
    }
  },
  {
    path: '/offline',
    name: 'offline',
    // component: () => import('@/views/OfflineView/OfflineView.vue'),
    component: OfflineView,
    meta: {
      title: 'オフライン',
      canShowOffline: true
    },
  },
  /*
  {
    path: '/pc',
    name: 'pc',
    component: () => import('@/views/PcView/PcView.vue'),
    meta: {
      title: 'スマホ専用',
      canShowOffline: true
    },
  },
  {
    path: '/howto',
    name: 'howto',
    component: () => import('@/views/HowtoView/HowtoView.vue'),
    meta: {
      title: '利用方法',
    }
  },
    */
  {
    path: '/agreement',
    name: 'agreement',
    component: () => import('@/views/AgreementView/AgreementView.vue'),
    meta: {
      title: '利用規約',
      canShowOffline: true
    }
  },
  {
    path: '/:path(.*)*',
    name: 'notfound',
    component: () => import('@/views/NotfoundView/NotfoundView.vue'),
    meta: {
      title: 'Not Found',
      canShowOffline: true
    }
  },

]



const router = createRouter({ //遷移後の動作
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior(to, from, savePosition) {

    // //BAD HACK  HOMEボタンが２度押されたときの動作
    // if (window.navigator.onLine && to.name == 'home' && from.name == 'home') {
    //   window.location.assign(location.origin + to.fullPath)
    //   return
    // }

    // return {selector: to.hash}

    if (to.hash) {  //アンカー

      //表示URLから「#」を取る
      // history.replaceState(null, null, location.pathname.split('/').pop());
      history.replaceState(null, null, location.href.replace(/#.+/, ''));
      
      const el = document.getElementById(to.hash.replace(/^#/, ''));
      if (from.name == to.name && ('scrollBehavior' in document.documentElement.style)) {
        //同一ページならスムーススクロール
        return window.scrollTo({top: el.offsetTop, behavior: 'smooth'});
      } else {
        //異なるページなら頭出しだけ
        return window.scrollTo(0, el.offsetTop);
      }
    }
    else if (savePosition) {  //戻る場合など同じスクロール位置に
      return savePosition
    }
    else {  //ページトップに移動
     return {top: 0}
    }
  },
  routes
})



//ページタイトル
const DEFAULT_TITLE = 'スタンプ帳'
router.afterEach((to) => {
  document.title = (to.meta.title) ? to.meta.title + '｜' + DEFAULT_TITLE : DEFAULT_TITLE
})


const last_update = (new Date()).getTime()

// let is_first = true


router.beforeEach((to/*, from*/) => {

  //クエリパラメータ引き回し
  const search_params = new URLSearchParams(window.location.search)
  const query = {}
  for(let [k, v] of search_params) query[k] = v


  //一定間隔置きに更新確認
  const is_standalone =  ((-1 != window.location.href.indexOf('?utm_source=homescreen')) || navigator.standalone) ? true : false
  if (is_standalone && window.navigator.onLine && to.meta.forceRefresh && ((new Date()).getTime() - last_update > constant.UPDATE_INTERVALS)) {  //12hour
      window.location.assign(location.origin + to.fullPath)
      return
  }

  // //BAD HACK  HOMEへの遷移はインターネットからロード
  // if (from.name && to.name == 'home' && window.navigator.onLine) {
  //   window.location.assign(location.origin + to.fullPath)
  //   return
  // }

  //実施期間外なら（meta.in_term  -1: 期間前, 0:期間内, 1:期間後）
    const date_from = new Date(Date.parse(constant.FROM))
    const date_to = new Date(Date.parse(constant.TO + ' 23:59:59'))
    const date_now = new Date()
    if (date_now < date_from) { //期間前
      to.meta.in_term = -1
      if (to.name != 'about') return { name: 'about', query: query }
    }
    else if (date_now > date_to) {  //期間後
      to.meta.in_term = 1
      if (to.meta.cantSeeOutOfTerm) {  //期間後は見られないページ
        if (to.name != 'about') return { name: 'about', query: query }
      }
    }
    else {
      to.meta.in_term = 0
    }


  //PCページへ
  // if (window.navigator.userAgent.match(/iPhone|Android.+Mobile/)) {
  //   if (to.name == "pc") {
  //     let url = (window.navigator.onLine) ? 'home' : 'about'
  //     return { name: url, query: query }
  //   }
  // }
  // else {
  //   if (to.name != "pc") {    //ループ防止
  //     return { name: "pc", query: query }
  //   }
  // }
  if (! window.navigator.userAgent.match(/iPhone|Android.+Mobile/)) {
    location.href = "/pc.html"
    return
  }

  //サインイン認証
  let signin_token = localStorage.getItem(constant.SIGNIN_TOKEN)
  to.meta.signin_token = (signin_token == null) ? false : signin_token


  let home = (to.meta.signin_token != false) ? 'home' : 'about'


  if (! window.navigator.onLine) {  //オフライン
    if (to.meta.signin_token == false) {  //認証まだ
      if (to.name != "offline") { //ループ防止
        return { name: "offline", query: query }
      }
    }
    else if (! to.meta.canShowOffline) {  //オフラインでは見られないページ
      return { name: home, query: query }
    }
  }
  else {  //オンライン
    //認証が必要なページだがまだサインインしていない
    if (to.meta.requiresAuth && to.meta.signin_token == false) {
      // return { name: "signin", query: query }
      return { name: home, query: query }
    }
    //サインイン後は表示できないページ
    else if (to.meta.noShowAfterAuth && to.meta.signin_token != false) {
      return { name: home, query: query }
    }
  }


  //初回QR読み込み前に注意ページを表示
  if (to.name == 'qr') {
    if (constant.GPS_VERSION) return { name: "gps", query: query }

    // if (is_first) { //初回はガイダンス表示
    //   if (from.name == 'qr_notice') {
    //     is_first = false
    //   }
    //   else {
    //     return { name: "qr_notice", query: query }
    //   }
    // }
/*    
    //iOS PWA：QR読み取りのURLをランダムに（iOS browser起動の場合はコメントアウト）
    if ((/iPad|iPhone|iPod/.test(navigator.userAgent)) && navigator.standalone) {
      if (window.navigator.onLine) {
        let url_obj = new URL(location.href)
        let url = url_obj.protocol + '//' + url_obj.hostname
        if (url_obj.port) url += ':' + url_obj.port
        url += '/qr/' + (Math.floor(Math.random() * 10000000)) + url_obj.search + url_obj.hash
        // return { name: 'qr_', params: {random: (Math.floor(Math.random() * 10000000))}, query: query }
        window.location.assign(url)
        return
      }
      else {  //offline
        return { name: "about", query: query }
      }
    }
*/
  }


  //初回GPS読み込み前に注意ページを表示
  if (to.name == 'gps') {
    if (! constant.GPS_VERSION) return { name: "qr", query: query }

    // if (is_first) { //初回はガイダンス表示
    //   if (from.name == 'gps_notice') {
    //     is_first = false
    //   }
    //   else {
    //     return { name: "gps_notice", query: query }
    //   }
    // }
  }

})




//エラーのときに強制リロード
router.onError(
  (error, /*to, from*/) => {
    if (error.message.includes("Failed to fetch dynamically imported module")) {
      console.warn(`${error.message}, force reload.`);
      // setTimeout(() => {
        alert("エラーが発生しました")
      // window.location.assign(to.fullPath);
        window.location.reload(true)
      // }, 3000)
    }
  }
);




export default router
