<template>
  <div class="stamp details_matter" v-cloak>
    <div class="details_matter_wrap">

      <div class="stamp_detail">
        <p v-if="is_new" class="new_icon">NEW</p>
        <div class="stamp_img no_select" :style="img_style"></div>

        <div class="stamp_info">
          <!-- ↓↓↓ 絵柄がシークレットの場合 -->
          <!-- <h2>{{ stamp.title }}</h2> -->
          <!-- ↓↓↓ 絵柄がシークレットでない場合 -->
          <h2>{{ stamp.stamp_title}}</h2>

          <template v-if="stamp.done && is_online">
            <p class="hint" v-if="stamp.stamp_caption != ''">{{ stamp.stamp_caption }}</p>
          </template>
          <template v-else>
            <p class="hint" v-if="stamp.hint != ''">{{ stamp.hint }}</p>
          </template>
        </div>

        <!-- スタンプ絵柄の情報（絵柄がシークレットの場合） -->
<!--
        <div class="stamp_section" v-if="stamp.done && (stamp.stamp_title != '' || stamp.stamp_caption != '')">
          <p class="stamp_title" v-if="stamp.stamp_title != ''">{{ stamp.stamp_title }}</p>
          <p class="stamp_caption" v-if="stamp.stamp_caption != ''">{{ stamp.stamp_caption }}</p>
        </div>
-->

        <div v-if="stamp.spot_img && is_online" class="spot_img">
          <img :src="'/img/spot_images/' + stamp.spot_img">
        </div>

        <!-- 観光スポットの情報 -->
        <div class="spot_section" v-if="stamp.spot_title != '' || stamp.spot_caption != ''">
          <p class="spot_title" v-if="stamp.spot_title != ''">{{ stamp.spot_title }}</p>
          <p class="spot_caption" v-if="stamp.spot_caption != ''">{{ stamp.spot_caption }}</p>
        </div>
        <!-- プロモーション -->
        <div class="promotion_section" v-if="stamp.promotion_title != '' || stamp.promotion_caption != ''">
          <p class="promotion_title" v-if="stamp.promotion_title != ''">{{ stamp.promotion_title }}</p>
          <p class="promotion_caption" v-if="stamp.promotion_caption != ''">{{ stamp.promotion_caption }}</p>
        </div>
        <!-- プロモーション -->
        <div class="time_promotion_section" v-if="stamp.done && (stamp.time_promotion_title != '' || stamp.time_promotion_caption != '')">
          <p class="time_promotion_title" v-if="stamp.time_promotion_title != ''">{{ stamp.time_promotion_title }}</p>
          <p class="time_promotion_caption" v-if="stamp.time_promotion_caption != ''">{{ stamp.time_promotion_caption }}</p>
        </div>

        <div class="map_wrapper" v-if="is_online">
        <!-- 地図 -->
          <!-- <iframe src="https://www.google.com/maps/embed?pb=!1m13!1m8!1m3!1d813.8700005352105!2d139.55012217235276!3d35.31890012810994!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzXCsDE5JzA3LjciTiAxMznCsDMzJzAwLjIiRQ!5e0!3m2!1sja!2sjp!4v1697842090812!5m2!1sja!2sjp" style="border:0;" allowfullscreen loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> -->
          <iframe :src="stamp.map" style="border:0;" allowfullscreen loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>

      </div>

    </div>


    <div class="btn_base_default square_gray btn_blackgray_border">
      <router-link v-if="! $router.options.history.state.back" :to="{name: 'home', query: query}">スタンプ帳</router-link>
      <router-link v-if="$router.options.history.state.back.match(/^\/qr\??/) || $router.options.history.state.back.match(/^\/gps\??/)" :to="{name: 'home', query: query}">スタンプ帳</router-link>
      <a v-else class="btn_half" href="#" @click.prevent="router.back()">戻る</a>
    </div>

  </div>
</template>



<script setup>
import { defineEmits, onMounted, onUnmounted, inject, watchEffect, ref } from 'vue'
import { onBeforeRouteUpdate, useRoute, useRouter } from 'vue-router'
import { useStore } from '@/stores/store'
import constant from '@/constant'

const store = useStore()
const router = useRouter()
const route = useRoute()

//パラメータ付URLによるキャッシュ回避
let stamp_id = route.params.stamp_id
onBeforeRouteUpdate(
  (to) => {
    stamp_id = to.params.stamp_id
//    console.log(stamp_id)
  }
)


//クエリパラメータ引き回し
const search_params = new URLSearchParams(window.location.search)
const query = {}
for(let [k, v] of search_params) query[k] = v


//id をキーに store.stamps を検索
const stamp = store.getStampById(stamp_id)
if (stamp === false) {  //該当データなし
  router.replace({name: 'home'})
}

const is_online = inject(constant.IS_ONLINE)

//スタンプ新規に獲得フラグ
const new_stamp = inject(constant.NEW_STAMP)
if (! new_stamp) throw new Error('provide/inject Error.');
const is_new = (new_stamp.stamp_id == stamp_id && new_stamp.is_new) ? true : false;
if (new_stamp.is_new) new_stamp.is_new = false; //初回のみの表示



//スタンプ画像設定
// const stamp_img = (stamp.done) ? 'stamp_' + stamp.img : 'no_stamp'
// const img_src = require('@/assets/images/stamps/' + stamp_img + '.png')

const stamp_img = (stamp.done) ? ((stamp.pending) ? constant.PENDING : stamp.img) : constant.NO_STAMP
const img_style = ref({
  'background-image': 'url(' + stamp_img + ')'
})

watchEffect(() => {
  const stamp_img2 = (stamp.done) ? ((stamp.pending) ? constant.PENDING : stamp.img) : constant.NO_STAMP
  img_style.value = {
    'background-image': 'url(' + stamp_img2 + ')'
  }
})


const emit = defineEmits(["mounted", "unmounted"])
onMounted(() => { emit("mounted") })
onUnmounted(() => { emit("unmounted") })


</script>


<style scoped src="./StampView.css"></style>
