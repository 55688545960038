<template>

    <div class="stamp_thumb" @click.prevent="click" v-cloak>
        <!-- <router-link :to="{name: 'stamp', params: {stamp_id: props.stamp_id}}"> -->
            <div class="stamp_img no_select" :style="img_style" :class="{'pending': pending}"></div>
            <!-- <p class="stamp_title" v-if="! props.done">{{ props.title}}</p> -->
            <p class="stamp_title">{{ props.title}}</p>
        <!-- </router-link> -->
    </div>

</template>


<script setup>
import { defineProps, defineEmits, ref, watchEffect } from 'vue'
import constant from '@/constant'

const props = defineProps({stamp_id: Number, title: String, img: String, done: Boolean, pending: Boolean})
const emits = defineEmits(["stamp_clicked"])

// const stamp_img = (props.done) ? ((props.pending) ? constant.PENDING : './img/stamps/stamp_' + props.img + '.png') : constant.NO_STAMP
const stamp_img = (props.done) ? ((props.pending) ? constant.PENDING : props.img) : constant.NO_STAMP
const img_style = ref({'background-image': 'url(' + stamp_img + ')'})


watchEffect(() => {
    // const stamp_img2 = (props.done) ? ((props.pending) ? constant.PENDING : './img/stamps/stamp_' + props.img + '.png') : constant.NO_STAMP
    const stamp_img2 = (props.done) ? ((props.pending) ? constant.PENDING : props.img) : constant.NO_STAMP
    img_style.value = {'background-image': 'url(' + stamp_img2 + ')'}
})


const click = () => {
    // router.push({name: 'stamp', params: {stamp_id: props.stamp_id}})
    emits("stamp_clicked", props.stamp_id)
}

</script>


<style scoped src="./StampThumb.css"></style>
