import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import Toaster from '@meforma/vue-toaster';



const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

const app = createApp(App)


// //全体エラー捕捉
// app.config.errorHandler = function (e/*, vm, info*/) {
//     if(e.errorCode === 'session_expired') {
//       // storeからセッション情報クリアしてログイン要求したい
//       alert('エラーが発生しました。ERR_001')
//     }
//     else if(e.errorCode === 'illegal_argument') {
//       // 入力値異常
//       alert('エラーが発生しました。ERR_002')
//     }
//     else if(e.errorCode === 'network_error') {
//       // 通信環境がいいとこで再度実行してくださいなど表示したい
//       alert('エラーが発生しました。ERR_003')
//     }
//     else {
//         // alert('エラーが発生しました。ERR_004')
//     }
//     return false
// }
  


app.use(pinia).use(router).use(Toaster, {position: 'top', maxToasts: 1}).provide('toast', app.config.globalProperties.$toast).mount('#app')





  