import { defineStore } from 'pinia'
import axios from 'axios'
import constant from '@/constant'

const SIGNIN_URL = 'https://test03.eco-inc.jp/apps/signin.php'
const STAMPIT_URL = 'https://test03.eco-inc.jp/apps/stampit.php'
const PENDING_URL = 'https://test03.eco-inc.jp/apps/pending.php'
const COMPLETE_DONE_URL = 'https://test03.eco-inc.jp/apps/slide.php'

export const useStore = defineStore({
  id: 'stamp',
  state: () => ({
    last_update: null,
    stamps: [],
    prize_complete_done: false, //応募済
    prize_bingo_done: false,    //応募済
  }),
  getters: {
    getStampById: (state) => {
        return (stamp_id) => {
            let stamp = state.stamps.filter(v => v.stamp_id == stamp_id)
            return (stamp && stamp.length > 0) ? stamp[0] : false
        }
        // return (stamp_id) => (state.stamps.filter(v => v.stamp_id == stamp_id))[0]
    },
    getStampByCode: (state) => {
        return (code) => {
            let stamp = state.stamps.filter(v => v.code == code)
            return (stamp && stamp.length > 0) ? stamp[0] : false
        }
        // return (code) => (state.stamps.filter(v => v.code == code))[0]
    },
    getStampsByDistance: (state) => {
        return (lat, lng, distance) => {    //distance はメートル
            const R = Math.PI / 180;
            let stamp = state.stamps.filter(v => {
                if ((! v.lat) || (! v.lng)) return false
                const d_lat = (v.lat - lat) * R
                const d_lng = (v.lng - lng) * R
                const a = Math.sin(d_lat / 2) * Math.sin(d_lat / 2) + Math.cos(lat * R) * Math.cos(v.lat * R) * Math.sin(d_lng / 2) * Math.sin(d_lng / 2)
                const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
                const d = 6371 * c * 1000
// console.dir(v.stamp_id + ": " + d + ", " + distance)
                return ((d <= distance) ? true : false)
            })
            return stamp
        }
    },
    getNumOfStampsObtained: (state) => {
        let stamp = state.stamps.filter(v => v.done == true)
        return stamp.length
    },
    isStampCompleted: (state) => {
        return ((state.getNumOfStampsObtained >= state.stamps.length) ? true : false)
    },
    getBingosNum: (state) => {
        let len = Math.sqrt(state.stamps.length)
        if ((len == 0) || (Math.floor(len) != len)) return 0
        let bingo_row = 0
        let bingo_line = 0
        let bingo_diagonally1 = 0
        let bingo_diagonally2 = 0

        //縦横
        for (let i = 1; i <= len; i++) {
            let bool_row = true
            let bool_line = true
            for (let j = 0; j < len; j++) {
                let stamp = state.getStampById(i + j * len)
                if (! stamp.done) bool_line = false
                stamp = state.getStampById((i - 1) * len + j + 1)
                if (! stamp.done) bool_row = false
                if ((! bool_line) && (! bool_row)) break
            }
            if (bool_row) bingo_row++
            if (bool_line) bingo_line++
        }

        let bool_diagonally1 = true
        let bool_diagonally2 = true
        for (let k = 0; k < len; k++) {
            let stamp2 = state.getStampById(1 + (len + 1) * k)
            if (! stamp2.done) bool_diagonally1 = false
            stamp2 = state.getStampById(len + (len - 1) * k)
            if (! stamp2.done) bool_diagonally2 = false
            if ((! bool_diagonally1) && (! bool_diagonally2)) break
        }
        if (bool_diagonally1) bingo_diagonally1++
        if (bool_diagonally2) bingo_diagonally2++
        return (bingo_row + bingo_line + bingo_diagonally1 + bingo_diagonally2)
    }

  },
  actions: {

    //サインイン
    signIn(email_or_token, password) {
        //stateの初期化
        this.$reset()
        const promise = new Promise(
            (resolve/*, reject*/) => {
                // axios.get(SINGIN_URL, {
                //     params: {
                //         email: email,
                //         password: password,
                //     }
                // }).then(
                let obj = (password == null) ? { signin_token: email_or_token } : { email: email_or_token, password: password }
                axios.post(SIGNIN_URL, obj,
                    { headers: { "Content-type": "application/x-www-form-urlencoded" } }
                ).then(
                    (v) => {
                        // const res = (('data' in v) && ('error' in v.data) && (v.data.error == 0) && ('token' in v.data) && (v.data.token)) ? v.data.token : false
                        // resolve(res)   //"token" or false
                        let res = false
                        if (! v) res = false
                        else if (! ('data' in v)) res = false
                        else if ((! ('error' in v.data)) || v.data.error != 0 || (! ('token' in v.data)) || (v.data.token == null) || (! ('stamps' in v.data)) || (v.data.stamps == null)) res = false
                        else {
                            // this.stamps = v.data.stamps
                            //DBのデータと同期
                        /*
                            for (let i = 0; i < v.data.stamps.length; i++) {
                                const obj = structuredClone(constant.STAMP_DATA)
                                for (let k in v.data.stamps[i]) {
                                    obj[k] = v.data.stamps[i][k]
                                }
                                this.stamps.push(obj)
                                // this.stamps[i] = structuredClone(obj)
                            }
                        */
                            for (let i = 0; i < v.data.stamps.length; i++) {
                                const obj = structuredClone(constant.STAMP_DATA)
                                Object.keys(constant.STAMP_DATA).forEach(function (k) {
                                    if (k in v.data.stamps[i]) obj[k] = v.data.stamps[i][k]
                                });
                                this.stamps.push(obj)
                                // this.stamps[i] = structuredClone(obj)
                            }
                            
                            res = v.data.token  //ログイントークン

                            //スタンプコンプリート応募済
                            if (('prize_complete_done' in v.data) && v.data.prize_complete_done) {
                                this.prize_complete_done = true
                            }

                            //ビンゴ達成応募済
                            if (('prize_bingo_done' in v.data) && v.data.prize_bingo_done) {
                                this.prize_bingo_done = true
                            }
                        }
                        resolve(res)
                    }
                ).catch(function (error) {
                    console.dir(error)
                    resolve(false)
                })
            }
        )
        return promise
    },

    //QRコード読み取りによるスタンプ獲得（code：QRで読み取った値）
    stampIt(signin_token, code) {
        let state = this
        let stamp = (this.stamps.filter(v => v.code == code))
        const promise = new Promise(
            (resolve/*, reject*/) => {
                if ((! stamp) || stamp.length == 0) resolve(false)
                else if (! window.navigator.onLine) {   //オフライン
                    stamp[0].pending = true
                    //現在時刻
                    let date = (new Date()).toLocaleString()
                    resolve({img: "pending", date: date, pending: true})
                }
                else {
                    // axios.get(STAMPIT_URL, {
                    //     params: {
                    //         signin_token: signin_token,
                    //         code: code,
                    //         stamp_id: stamp[0].id,
                    //     }
                    // }).then(
                    axios.post(STAMPIT_URL, {
                            signin_token: signin_token,
                            code: code,
                            // stamp_id: stamp[0].id,
                        },
                        { headers: { "Content-type": "application/x-www-form-urlencoded" } }
                    ).then(
                        (v) => {
                            let res = false
                            if (! v) res = false
                            else if (! ('data' in v)) res = false
                            else if ((! ('error' in v.data)) || v.data.error != 0 || (! ('img' in v.data)) || (v.data.img == null) || (! ('date' in v.data)) || (v.data.date == null)) res = false
                            else res = v.data
                            resolve(res)   //"token" or false
                        }
                    ).catch(function (error) {
                        console.dir(error)
                        resolve(false)
                    })
                }
            }
        )
        promise.then(function(res) {
            if (res == false) return false
            // state.last_update = res.date
            state.last_update = (new Date()).toLocaleString()
            stamp[0].date = res.date
            stamp[0].img = res.img
            if ("stamp_title" in res) stamp[0].stamp_title = res.stamp_title
            if ("stamp_caption" in res) stamp[0].stamp_caption = res.stamp_caption
            if ("time_promotion_title" in res) stamp[0].time_promotion_title = res.time_promotion_title
            if ("time_promotion_caption" in res) stamp[0].time_promotion_caption = res.time_promotion_caption
            stamp[0].done = true
        })
        return promise
    },

    //オフライン時に押したスタンプがあれば同期
    synchro_pending_stamps(signin_token) {
        let stamp = (this.stamps.filter(v => v.pending == true))    //ペンディングフラグが立っているスタンプデータの配列
        if (stamp.length == 0) return false
        const codes = stamp.map((obj) => ({code: obj.code, date: obj.date}))
        const promise = new Promise(
            (resolve, reject) => {
                // axios.get(PENDINT_URL, {
                //     params: {
                //         signin_token: signin_token,
                //         codes: JSON.stringify(codes),
                //     }
                // }).then(
                axios.post(PENDING_URL, {
                        signin_token: signin_token,
                        codes: JSON.stringify(codes),
                        //日付
                    },
                    { headers: { "Content-type": "application/x-www-form-urlencoded" } }
                ).then(    
                    (v) => {
                        if (! v) resolve(false)
                        else if (! ('data' in v)) resolve(false)
                        else if ((! ('error' in v.data)) || v.data.error != 0 || (! ('stamps' in v.data)) || (v.data.stamps == null)) resolve(false)
                        else {
                            for (const elem of v.data.stamps) {
                                const stamp = this.getStampById(elem.stamp_id)
                                if (stamp) {
                                    stamp.img = elem.img
                                    stamp.stamp_title = elem.stamp_title
                                    stamp.stamp_caption = elem.stamp_caption
                                    stamp.time_promotion_title = elem.time_promotion_title
                                    stamp.time_promotion_caption = elem.time_promotion_caption
                                    stamp.pending = false
                                }
                            }
                            resolve(v.data)   
                        }
                     }
                ).catch(function (error) {
                    console.dir(error)
                    reject(false)
                })
            }
        )
// console.dir(promise)
        return promise
    },

    //スタンプコンプリートをDB記録
    slide_done(signin_token) {
        const promise = new Promise(
            (resolve/*, reject*/) => {
                axios.post(COMPLETE_DONE_URL, {
                        signin_token: signin_token,
                    },
                    { headers: { "Content-type": "application/x-www-form-urlencoded" } }
                ).then(
                    (v) => {
                        let res = false
                        if (! v) res = false
                        else if (! ('data' in v) || (! ('error' in v.data))) res = false
                        else res = v.data
                        resolve(res)
                    }
                ).catch(function (error) {
                    console.dir(error)
                    resolve(false)
                })
            }
        )
        return promise
    },

  },
  persist: true //localStorage
//   persist: {
//     storage: sessionStorage,
//   }
})
