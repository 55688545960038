<!-- 現在、利用していないコンポーネント -->
<template>
    <div class="modal_win" v-cloak @click.stop="stop_event" @touchstart.stop="stop_event" @touchmove.stop="stop_event" @touchend.stop="stop_event">
      <div class="modal_close" @click.prevent="$emit('modal_close')"><span></span></div>
      <div class="modal_main">
        <slot @modal_close="$emit('modal_close')"></slot>
      </div>
      <!-- <button @click.prevent="$emit('modal_close')">CLOSE</button> -->
    </div>
</template>



<script setup>
  //イベントをモーダルの下に伝播させない
  const stop_event = (e) => {
    e.stopPropagation()
    return false
  }
</script>


<style scoped src="./ModalWin.css"></style>
