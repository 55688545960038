/* eslint-disable no-console */


// import { precacheAndRoute } from 'workbox-precaching';
// precacheAndRoute(self.__WB_MANIFEST || []);


import constant from '@/constant'

import { register } from 'register-service-worker'


if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready () {
      console.log(
        'App is being served from cache by a service worker.\n' +
        'For more details, visit https://goo.gl/AFskqB'
      )
    },
    registered () {
      console.log('Service worker has been registered.')
    },
    cached () {
      console.log('Content has been cached for offline use.')
    },
    updatefound () {
      console.log('New content is downloading.')
    },
    updated (registration) {
      console.log('New content is available; please refresh.')
      if (registration == undefined || registration == null || registration == false) return  //PCでの初回アクセス対策
      if (registration.waiting == undefined || registration.waiting.state != "installed") return

      document.dispatchEvent(
        new CustomEvent(constant.SW_UPDATED, { detail: registration })
      )
    }, 
    offline () {
      console.log('No internet connection found. App is running in offline mode.')
    },
    error (error) {
      console.error('Error during service worker registration:', error)
    }
  })
}
