<template>
  <div class="home_area" id="about-top" v-cloak>
    <!-- <h1>{{ route.meta.title }}</h1> -->
    <div class="contents_details">
      <!-- 通常説明書き用エリア -->
      <div class="contents_details_text">
        <h2> <img class="about_img" src="@/assets/images/about_view/ashirai_wagara3.png" alt="あしらい"> <img src="@/assets/images/about_view/ttl.png" alt="鎌倉七福神巡り"> </h2>
        <div class="about_desc">
          <p v-if="constant.GPS_VERSION" class="about_desc_ttl">スマホを手に鎌倉の寺社を巡って<br>七福神スタンプを集めよう!</p>
          <p v-else class="about_desc_ttl">スマホでQRコードを読み取って<br>七福神スタンプを集めよう!</p>
          <p class="about_desc_lead">古都鎌倉の七つの寺社に祀られている<br>幸運を招く七福神を巡拝できる1日コース。<br>スマホでかわいい七福神のスタンプを<br>手に入れながら「福」も集めて周りましょう。</p>
        </div>
        <div class="about_period">
          <dl><dt class="about_period_ttl">実施期間</dt>
            <dd>{{ period }}</dd>
          </dl>
        </div>

        <div class="about_howto">
          <div class="about_howto_area">
            <h2 class="about_ttl">
              <!-- スタンプの集め方 -->
              <img class="about_ttl_howto" src="@/assets/images/about_view/howto_ttl.png" alt="スタンプの集め方">
            </h2>
            <p v-if="constant.GPS_VERSION">スマホを持って鎌倉の<br>七福神ゆかりの寺社を訪れると<br>位置情報からスタンプをGETできます</p>
            <p v-else>七福神ゆかりの鎌倉の寺社を訪れて、<br>スタンプスポットのQRコードを<br>スマホのカメラで読み込んでください</p>
          </div>
          <div class="about_howto_desc">
            <img v-if="constant.GPS_VERSION" src="@/assets/images/about_view/howto_stamp_gps.png" alt="スタンプの集め方">
            <img v-else src="@/assets/images/about_view/howto_stamp.png" alt="スタンプの集め方">
            <div class="btn_base_single">
              <a class="howto_btn btn_middle" href="#" @click.prevent="$emit('show_modal', 'ModalHowto')">詳しい利用方法</a>
            </div>
          </div>
          <div class="about_howto_present">
            <img class="about_howto_pre_sub" src="@/assets/images/about_view/howto_sub_ttl.png" alt="プレゼントがもらえる">
            <div class="btn_base_single"><!-- 横100リンク（地図リンク）-->
              <router-link class="btn_present btn_middle" :to="{name: 'prizeexchange', query: query}">プレゼント引換場所</router-link></div>
          </div>
        </div>

        <div class="about_present">
          <h2 class="about_ttl"> <img class="about_ttl_pre" src="@/assets/images/about_view/present_ttl.png" alt="プレゼント">
            <!-- プレゼント -->
          </h2>
          <div class="about_present_item about_present_complete">
            <!-- <img src="@/assets/images/about_view/complete_pre_ttl.png" alt=""> -->
            <p class="about_present_desc">9ヶ所全てのスタンプを集めると<br>引換所でプレゼント！</p> <img class="about_present_img" src="@/assets/images/about_view/complete_pre.png" alt="七福神お守り">
            <h3 class="about_present_ttl">鎌倉七福神お守り</h3>
            <p>オリジナルデザイン・非売品</p>
          </div>
          <div class="about_present_item about_present_bingo">
            <p class="about_present_desc">縦・横・斜めに揃ったビンゴの数を<br>応募口数として抽選</p> <img class="about_present_img" src="@/assets/images/about_view/bingo_pre.png" alt="旅館宿泊券">
            <h3 class="about_present_ttl">鎌倉「古の里旅館」宿泊券</h3>
            <p>ペア宿泊券(一泊)</p>
          </div>
        </div>
        <div class="about_spot">
          <h2 class="about_ttl">
            <!-- スタンプスポット --><img src="@/assets/images/about_view/spot_ttl.png" alt="スタンプスポット"></h2>
          <div class="details_conts_note">
            <p>どの順番でめぐってもOK。<br>散歩気分で1日で周れます。</p>
            <ul class="about_spot_list">

<!--
              <li class="about_spot_item" v-for="n in 9" :key="n">
                <SpotBanner :stamp_id="n"></SpotBanner>
              </li>
-->

              <li class="about_spot_item">
                <!-- <img src="@/assets/images/about_view/spot1.png" alt="鶴岡八幡宮" @click.prevent="click_spot(1)"> -->
                <SpotBanner :stamp_id="1"></SpotBanner>
              </li>
              <li class="about_spot_item">
                <!-- <img src="@/assets/images/about_view/spot2.png" alt="宝戒寺" @click.prevent="click_spot(2)"> -->
                <SpotBanner :stamp_id="2"></SpotBanner>
              </li>
              <li class="about_spot_item">
                <!-- <img src="@/assets/images/about_view/spot3.png" alt="妙隆寺" @click.prevent="click_spot(3)"> -->
                <SpotBanner :stamp_id="3"></SpotBanner>
              </li>
              <li class="about_spot_item">
                <!-- <img src="@/assets/images/about_view/spot4.png" alt="本覚寺" @click.prevent="click_spot(4)"> -->
                <SpotBanner :stamp_id="4"></SpotBanner>
              </li>
              <li class="about_spot_item">
                <!-- <img src="@/assets/images/about_view/spot6.png" alt="御霊神社" @click.prevent="click_spot(8)"> -->
                <SpotBanner :stamp_id="6"></SpotBanner>
              </li>
              <li class="about_spot_item">
                <!-- <img src="@/assets/images/about_view/spot8.png" alt="鶴岡八幡宮" @click.prevent="click_spot(7)"> -->
                <SpotBanner :stamp_id="8"></SpotBanner>
              </li>
              <li class="about_spot_item">
                <!-- <img src="@/assets/images/about_view/spot9.png" alt="鎌倉駅" @click.prevent="click_spot(5)"> -->
                <SpotBanner :stamp_id="9"></SpotBanner>
              </li>
              <li class="about_spot_item">
                <!-- <img src="@/assets/images/about_view/spot7.png" alt="浄智寺" @click.prevent="click_spot(9)"> -->
                <SpotBanner :stamp_id="7"></SpotBanner>
              </li>
              <li class="about_spot_item">
                <!-- <img src="@/assets/images/about_view/spot5.png" alt="長谷寺" @click.prevent="click_spot(6)"> -->
                <SpotBanner :stamp_id="5"></SpotBanner>
              </li>
            </ul>
          </div>
          <div class="btn_base_single">
            <!-- 横100リンク（地図リンク）-->
            <router-link class="btn_map btn_middle" :to="{name: 'map', query: query}"><i class="fa-solid fa-location-dot"></i>地図を確認する</router-link>
          </div>
        </div>
      </div>
      <!-- 通常リンク 2列 -->
      <!-- <div class="btn_base_default"> -->
      <!-- <a href="#" @click.prevent="$emit('show_modal', 'ModalHowto')"><i class="fa-solid fa-question"></i>利用方法</a> -->
      <!-- <a href="#" @click.prevent="$emit('show_modal', 'ModalAgreement')"><i class="fa-solid fa-book-open-reader"></i>利用規約</a> -->
      <!-- <router-link class="navbtn" :to="{name: 'agreement', query: query}">利用規約</router-link> 
        </div>-->
    </div>
    <!-- 通常説明書き用エリア -->
    <!-- 横100リンク（地図リンク）-->
    <!-- <div class="btn_base_single btn_map">
        <router-link :to="{name: 'map', query: query}"><i class="fa-solid fa-location-dot"></i>地図を確認する</router-link>
      </div> -->
    <div v-if="route.meta.signin_token == false" id="register" class="btn_base_box">
          <!-- 登録用リンク -->
          <h2 class="about_register_ttl">
            <!-- スタンプラリーに<br>参加する -->
            <img src="@/assets/images/about_view/join_ttl.png" alt="スタンプラリーに参加する">
          </h2>
          <p class="about_register_desc">スタンプを集めるにはご利用登録が必要です</p>
          <div class="btn_base_box_items btn_entry">
            <p>まだご利用登録されていない方</p>
            <router-link class="btn_middle" :to="{name: 'signup', query: query}">新規登録</router-link>
          </div>
          <div class="btn_base_box_items btn_singin">
            <p>ご利用登録が完了されている方</p>
            <router-link class="btn_middle" :to="{name: 'signin', query: query}">サインイン</router-link>
          </div>
          <div class="btn_base_default">
            <!-- <router-link class="navbtn" :to="{name: 'agreement', query: query}">利用規約</router-link> -->
            <a href="#" @click.prevent="$emit('show_modal', 'ModalAgreement')">利用規約</a>
          </div>
    </div>
    <div class="about_home_btn">
      <img src="@/assets/images/about_view/ttl_option.png" @click.prevent="scroll_top" alt="TOPに戻るボタン">
      <p>TOP</p>
    </div>
  </div>
</template>



<script setup>
import SpotBanner from '@/components/SpotBanner/SpotBanner'
import { defineEmits, onMounted, onUnmounted } from 'vue'
// import { useRoute, useRouter } from 'vue-router'
import { useRoute } from 'vue-router'
import constant from '@/constant'

const route = useRoute()
// const router = useRouter()

//実施期間
const date_from = new Date(Date.parse(constant.FROM))
const date_to = new Date(Date.parse(constant.TO))
let dayname = ['日', '月', '火', '水', '木', '金', '土']
let period = date_from.getFullYear() + '年'
period += (date_from.getMonth() + 1) + '月'
period += date_from.getDate() + '日'
period += '(' + dayname[date_from.getDay()] + ')'
period += ' 〜  '
if (date_from.getFullYear() != date_to.getFullYear()) {
  period += date_to.getFullYear() + '年'
}
period += (date_to.getMonth() + 1) + '月'
period += date_to.getDate() + '日'
period += '(' + dayname[date_to.getDay()] + ')'


// //スタンプスポット・クリック
// const click_spot = (id) => {
//   if (route.meta.signin_token == false) return false
//   router.push({name: 'stamp', params: {stamp_id: id}, query: query})
// }


//ページトップへスクロール
const scroll_top = () => {
  document.getElementById('about-top').scrollIntoView({
    behavior: 'smooth',
    block: 'start'
  })
}


//クエリパラメータ引き回し
const search_params = new URLSearchParams(window.location.search)
const query = {}
for(let [k, v] of search_params) query[k] = v


const emit = defineEmits(["mounted", "unmounted", "show_modal"])
onMounted(() => { emit("mounted") })
onUnmounted(() => { emit("unmounted") })

</script>



<style scoped src="./AboutView.css"></style>
